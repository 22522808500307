import React, { useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { QuestionCircleOutlined } from '@ant-design/icons';

import LeadsExclusionContext from '../../../../context/adminLeadsExclusionContext';
import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import { API_BASE_URL } from '../../../../config/baseURLs';

const Actions = (p) => {
	const { filterUniqueIdsAndRecent, setData, data } = useContext(
		LeadsExclusionContext
	);
	const [deleteQueuePopup, setDeleteQueuePopup] = useState(false);

	const closeDeleteQueuePopup = () => {
		setDeleteQueuePopup(false);
	};

	const openDeletePopUp = () => {
		setDeleteQueuePopup(true);
	};

	const deleteQueue = (id) => {
		axios
			.delete(`${API_BASE_URL}/admin/leads-exclusion/${id}`)
			.then((response) => {
				setDeleteQueuePopup(false);
				setData(
					filterUniqueIdsAndRecent(
						data?.filter((item) => item.id !== p?.data?.id)
					)
				);

				message.success(response.data?.message);
			})
			.catch(() => {
				message.error('Something went wrong please try again later!');
			});
	};

	return (
		<Popconfirm
			placement="bottomLeft"
			okButtonColor="red"
			open={deleteQueuePopup}
			title="Delete"
			description="You cannot undo this action. Do you confirm the deletion of the selected row?"
			okText="Yes"
			cancelText="Cancel"
			onConfirm={() => deleteQueue(p.data?.id)}
			onCancel={closeDeleteQueuePopup}
			icon={
				<QuestionCircleOutlined
					style={{
						color: 'red',
					}}
				/>
			}
		>
			<ToolTip title="Delete">
				<RiDeleteBin6Fill
					style={{
						fontSize: '18px',
						cursor: 'pointer',
						color: '#C00000',
						display: 'flex',
						alignItems: 'center',
					}}
					onClick={openDeletePopUp}
				/>
			</ToolTip>
		</Popconfirm>
	);
};

export default Actions;
