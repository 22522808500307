import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { BranchesOutlined } from '@ant-design/icons';
import { Radio, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { HiOutlineSearch } from 'react-icons/hi';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import { useQuery } from '../../hooks/use-query';
import EdenContext from '../../context/edenContext';
import NotificationBar from '../../components/notificationBar';
import Input from '../../components/input';
import Button from '../../components/button';
import Select from '../../components/select';
import NoData from '../../components/nodata';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray, titleCase } from '../../utils';
import ToolTip from '../../components/tooltip';
import MPNDetails from './MPNDetails';
import Actions from './Actions';
import CustomerPricesContext from '../../context/customerPricesContext';
import {
	dateFilterParams,
	dollarFilterParams,
	numberFilterParams,
} from '../../filters';
import { COUNTRY_CODES } from '../../columns';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';

import styles from './customerPrices.module.scss';

const CustomerPrices = () => {
	const { query } = useQuery();
	const mailid = query.get('id');
	const customer = query.get('customer');
	const salesrep = query.get('salesrep');
	const dataView = query.get('view');
	const { messageURl } = useContext(EdenContext);
	const { view, setView } = useContext(CustomerPricesContext);
	const gridRef = useRef();

	const [searchValue, setSearchValue] = useState('');
	const [gridApi, setGridApi] = useState(null);
	const [rowdata, setRowData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);
	const [count, setCount] = useState(0);
	const [currentTime, setCurrentTime] = useState(moment().format());
	const [dateRange, setDateRange] = useState('1W');
	const [showLeaderCrm, setShowLeadorCrm] = useState('');
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [showBorders, setShowBorders] = useState(false);

	const [rfqColumns] = useState([
		{ field: 'INTERNET_MESSAGE_ID', filter: 'agSetColumnFilter', hide: true },
		{
			headerName: 'Actions',
			field: 'MAIL_ID',
			cellClass: 'hyperlinks',
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(Actions),
		},
		{ headerName: 'Currency Detected', field: 'CURRENCY_DETECTED', hide: true },
		{
			headerName: 'Inc. Date/Time',
			field: 'DATE_TIME',
			sort: 'desc',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			minWidth: 150,
		},
		{
			headerName: 'Country',
			field: 'COUNTRY',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
		},
		{ headerName: 'PO?', field: 'PO_IN_HAND' },
		{
			headerName: 'MPNs',
			field: 'uniqueMails',
			cellRenderer: memo(Actions),
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			maxWidth: 80,
		},
		{
			field: 'SALES_REP_NAME',
			headerName: 'SR',
			cellRenderer: (p) => (
				<span
					className={styles['font-weight']}
					style={{
						color:
							p.data.IS_REPLIED === 'No'
								? 'red'
								: p.data.IS_REPLIED === 'Yes'
								? 'green'
								: 'black',
					}}
				>
					{titleCase(p.value)}
				</span>
			),
			maxWidth: 100,
		},
		{
			headerName: 'Customer',
			field: 'ACCOUNT',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? titleCase(p.value) : ''}
				</span>
			),
		},
		{
			field: 'SUBJECT',
			headerName: 'Subject',
			headerClass: styles['cd-header-child'],
			cellRenderer: (p) => (
				<>
					<ToolTip placement="bottomLeft" title={p?.value}>
						<span className={styles['data']}>{p?.value}</span>
					</ToolTip>
				</>
			),
			enableValue: true,
			aggFunc: 'subject',
		},
		{
			field: 'FROM_EMAIL',
			headerName: 'Email',
		},
		{
			field: 'ACCOUNT_NAME',
			headerName: 'Contact',
		},
		{
			field: 'ACCOUNT_TYPE',
			headerName: 'Relationship',
			minWidth: 100,
		},
		{
			field: 'COMPANY_TYPE',
			headerName: 'Type',
			minWidth: 100,
		},
		{
			field: 'TO_EMAIL',
			headerName: 'To',
		},
		{
			field: 'IS_REPLIED',
			headerName: 'Responded?',
			maxWidth: 120,
			cellRenderer: (p) => (
				<span
					className={styles['font-weight']}
					style={{
						color:
							p.value === 'No' ? 'red' : p.value === 'Yes' ? 'green' : 'black',
					}}
				>
					{p.value ? p.value : ''}
				</span>
			),
		},
		{
			field: 'REPLIED_BY_NAME',
			headerName: 'Responded By',
			cellRenderer: (p) => (
				<ToolTip title={p.data.REPLIED_BY_EMAIL}>{p.value}</ToolTip>
			),
		},
	]);

	const [mpnColumns] = useState([
		{ field: 'INTERNET_MESSAGE_ID', filter: 'agSetColumnFilter', hide: true },
		{
			headerName: 'Actions',
			field: 'MAIL_ID',
			floatingFilter: false,
			cellClass: 'hyperlinks',
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(Actions), //add currency here and mail
		},
		{
			headerName: 'Inc. Date/Time',
			field: 'DATE_TIME',
			sort: 'desc',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value
						? moment
								.utc(p.value)
								.tz('Asia/Singapore')
								.format('MMM-DD-YYYY hh:mm A')
						: ''}
				</span>
			),
			minWidth: 150,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
		},
		{
			headerName: 'Customer',
			field: 'ACCOUNT',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? titleCase(p.value) : ''}
				</span>
			),
		},
		{
			headerName: 'MPN',
			field: 'MPN',
			headerClass: styles['green-header'],
			cellRenderer: (p) => {
				const inputValue = (
					document.getElementById('searchValue')?.value || ''
				).toLowerCase();
				const cellValue = (p.value || '').toLowerCase();

				const index = cellValue.indexOf(inputValue);
				if (index !== -1) {
					const beforeHighlight = cellValue.substring(0, index);
					const highlighted = cellValue.substring(
						index,
						index + inputValue.length
					);
					const afterHighlight = cellValue.substring(index + inputValue.length);

					return (
						<span className={styles['font-size']}>
							{beforeHighlight.toUpperCase()}
							<strong style={{ color: '#0070C0' }}>
								{highlighted.toUpperCase()}
							</strong>
							{afterHighlight.toUpperCase()}
						</span>
					);
				} else {
					return (
						<span className={styles['font-size']}>
							{cellValue.toUpperCase()}
						</span>
					);
				}
			},
			minWidth: 85,
		},
		{
			headerName: 'Brand',
			field: 'BRAND',
			headerClass: styles['green-header'],
			minWidth: 100,
		},
		{
			headerName: 'QTY',
			field: 'QTY',
			headerClass: styles['green-header'],
			filter: 'agSetColumnFilter',
			cellRenderer: (p) => {
				const number = parseInt(p.value);
				return <>{isNaN(number) ? p.value : number.toLocaleString('en-US')}</>;
			},
			filterParams: numberFilterParams,
			maxWidth: 100,
		},
		{ field: 'FRANCHISED', headerName: 'Franchised?', maxWidth: 110 },
		{
			headerName: 'Subtotal',
			field: 'EXTENDED_PRICE',
			cellClass: 'numberType',
			cellRenderer: (p) => (
				<>{p.value && `$ ${p.value?.toLocaleString('en-US')}`}</>
			),
			filter: 'agSetColumnFilter',
			filterParams: dollarFilterParams,
		},
		{ headerName: 'TP', field: 'TARGET_PRICE' },
		{
			headerName: 'EOL?',
			field: 'OBSOLESCENCE',
			maxWidth: 80,
		},
		{ headerName: 'PO?', field: 'PO_IN_HAND', maxWidth: 80 },
		{
			headerName: 'Matches',
			field: 'FOUND_SUPPLIER_COUNT',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			maxWidth: 100,
		},
		{
			headerName: '#FC',
			field: 'FC_SOURCES',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			maxWidth: 80,
			cellRenderer: (p) =>
				p.value ? (
					<ToolTip title="Click here to see FindChips results for this MPN">
						<Link
							style={{
								color: '#0086D2',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							to={`${p.data.FC_LINK}`}
							target="_blank"
						>
							{p.value}
						</Link>
					</ToolTip>
				) : (
					''
				),
		},
		{
			headerName: '#Alts',
			field: 'ALTERNATES.count',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: memo(MPNDetails),
			maxWidth: 80,
		},
		// {
		// 	headerName: '#NC',
		// 	field: 'NC_COUNT',
		// 	filter: 'agSetColumnFilter',
		// 	filterParams: numberFilterParams,
		// 	maxWidth: 80,
		// 	cellRenderer: (p) =>
		// 		p.value ? (
		// 			<ToolTip title="Click here to see netComponents results for this MPN">
		// 				<Link
		// 					style={{
		// 						color: 'green',
		// 						fontWeight: 'bold',
		// 						textDecoration: 'none',
		// 					}}
		// 					to={`/netcomponents?mpn=${p.data.MPN}`}
		// 					target="_blank"
		// 				>
		// 					{p.value}
		// 				</Link>
		// 			</ToolTip>
		// 		) : (
		// 			''
		// 		),
		// },
		// {
		// 	headerName: 'Product Type',
		// 	field: 'PRODUCT_TYPE',
		// 	maxWidth: 120,
		// },
		// {
		// 	headerName: 'Product Category',
		// 	field: 'PRODUCT_CAT',
		// },
	]);

	const onGridReady = (p) => {
		setGridApi(p.api);
		p.api.autoSizeAllColumns();
	};

	const fetchSupplierPricesToday = async () => {
		setView(dataView === 'mpn-view' ? 'mpn-view' : 'rfq-view');
		setLoading(true);
		setSelectedRows([]);
		setCount(0);

		let allData = [];
		var pageSize = 200000; // Number of items per request
		var maxRequests = 5; // Maximum number of requests to make...
		if (dateRange === '1W') {
			pageSize = 100000;
			maxRequests = 1;
		}

		try {
			// Create an array of promises for each request
			const promises = Array.from({ length: maxRequests }, (_, i) => {
				const page = i + 1;
				const url =
					customer || salesrep
						? `https://internal.supremecomponents.com/api/externalgateway.php?route=get_customer_prices_today&interval=${dateRange}&filter=${showLeaderCrm}&account=${customer}&salesrep=${salesrep}&page=${page}&pageSize=${pageSize}`
						: `https://internal.supremecomponents.com/api/externalgateway.php?route=get_customer_prices_today&interval=${dateRange}&filter=${showLeaderCrm}&page=${page}&pageSize=${pageSize}`;
				return axios.get(url);
			});

			// Wait for all promises to resolve
			const responses = await Promise.all(promises);

			// Process each response
			responses.forEach((response) => {
				const data = response?.data;
				if (Array.isArray(data)) {
					allData = [...allData, ...data];
				} else {
					console.error('Unexpected data structure:', data);
				}
			});

			setRowData(allData);
			setCurrentTime(moment().format());
			setError(null);
		} catch (err) {
			console.error('Error fetching data:', err);
			setError(err);
			setRowData(null);
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = () => {
		if (searchValue?.length > 3) {
			setLoading(true);
			setSelectedRows([]);

			setCount(0);
			axios
				.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=get_customer_prices&keyword=${searchValue}`
				)
				.then((response) => {
					const data = response?.data;

					// Ensure data is an array before setting it to rowdata
					if (Array.isArray(data)) {
						setRowData(data);
					} else {
						console.error('Unexpected data structure:', data);
						setRowData([]);
					}

					setCurrentTime(moment().format());
					setError(null);
				})
				.catch((err) => {
					setError(err);
					setRowData(null);
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (searchValue?.length <= 0) {
			fetchSupplierPricesToday();
		}
		// eslint-disable-next-line
	}, [searchValue, dateRange, showLeaderCrm]);

	const exportToExcel = () => {
		const CustomerRfqsExcelParams = {
			sheetName: view === 'rfq-view' ? 'Customer RFQs' : 'Customer MPNs',
			fileName:
				view === 'rfq-view' ? 'Customer RFQs.xlsx' : 'Customer MPNs.xlsx',
			columnKeys:
				view === 'rfq-view'
					? [
							'MAIL_ID',
							'CURRENCY_DETECTED',
							'DATE_TIME',
							'COUNTRY',
							'PO_IN_HAND',
							'uniqueMails',
							'SALES_REP_NAME',
							'ACCOUNT',
							'SUBJECT',
							'FROM_EMAIL',
							'ACCOUNT_NAME',
							'ACCOUNT_TYPE',
							'COMPANY_TYPE',
							'TO_EMAIL',
							'IS_REPLIED',
							'REPLIED_BY_NAME',
					  ]
					: [
							'MAIL_ID',
							'DATE_TIME',
							'ACCOUNT',
							'COUNTRY',
							'MPN',
							'BRAND',
							'QTY',
							'FRANCHISED',
							'EXTENDED_PRICE',
							'TARGET_PRICE',
							'OBSOLESCENCE',
							'PO_IN_HAND',
							'FOUND_SUPPLIER_COUNT',
							'FC_SOURCES',
							'ALTERNATES.count',
							'NC_COUNT',
							'PRODUCT_TYPE',
							'PRODUCT_CAT',
					  ],
			processCellCallback: (p) => {
				if (p.column.colId === 'DATE_TIME') {
					if (p?.value) {
						return moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'ACCOUNT') {
					if (p?.value) {
						return titleCase(p.value);
					}
					return '';
				}
				if (p.column.colId === 'MAIL_ID') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.colId === 'QTY') {
					const number = parseInt(p.value);
					if (p.value && typeof number === 'number' && !isNaN(number)) {
						return number?.toLocaleString('en-US');
					}
					return p.value;
				}
				return p.value;
			},
		};
		gridApi.exportDataAsExcel(CustomerRfqsExcelParams);
	};

	const processCellForClipboard = (p) => {
		if (p.column.colId === 'DATE_TIME') {
			if (p?.value) {
				return moment
					.utc(p.value)
					.tz('Asia/Singapore')
					.format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'ACCOUNT') {
			if (p?.value) {
				return titleCase(p.value);
			}
			return '';
		}
		if (p.column.colId === 'MAIL_ID') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'QTY') {
			const number = parseInt(p.value);
			if (p.value && typeof number === 'number' && !isNaN(number)) {
				return number?.toLocaleString('en-US');
			}
			return p.value;
		}

		return p.value;
	};

	const copyResultsToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: ['MPN', 'BRAND', 'QTY', 'FOUND_SUPPLIER_COUNT'],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: 'Results copied to your clipboard!',
		});
	};

	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: [
					'MAIL_ID',
					'DATE_TIME',
					'ACCOUNT',
					'SUBJECT',
					'FROM_EMAIL',
					'ACCOUNT_NAME',
					'ACCOUNT_TYPE',
					'SALES_REP_NAME',
					'TO_EMAIL',
					'IS_REPLIED',
					'NO_OF_LINES',
					'MPN',
					'BRAND',
					'QTY',
					'FOUND_SUPPLIER_COUNT',
					'uniqueMails',
				],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: `${selectedRows?.length} Rows copied to your clipboard!`,
		});
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			cellStyle: { borderRight: showBorders && '1px solid #EAECEE' },
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[showBorders]
	);

	// export excel sheet style
	const excelStyles = useMemo(() => {
		return [
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
			{
				id: 'numberType',
				numberFormat: {
					format: '$ #,##0.00',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{
				id: 'hyperlinks',
				font: {
					underline: 'Single',
					color: '#358ccb',
				},
			},
		];
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
				setSearchValue('');
				setGlobalFilterValue('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	const countMailIdOccurrences = (array) => {
		// Check if the input is a valid array
		if (!Array.isArray(array)) {
			return { uniqueObjects: [], occurrences: {} };
		}

		const counts = {};
		const uniqueObjects = {};

		array.forEach((obj) => {
			const mailId = obj?.MAIL_ID; // Ensure obj and obj.MAIL_ID exist
			if (mailId) {
				counts[mailId] = (counts[mailId] || 0) + 1;
				uniqueObjects[mailId] = obj;
			}
		});

		// Count occurrences
		const occurrences = {};
		Object.values(counts).forEach((count) => {
			occurrences[count] = (occurrences[count] || 0) + 1;
		});

		// Add count to each unique object
		Object.values(uniqueObjects).forEach((obj) => {
			const mailId = obj?.MAIL_ID; // Ensure obj and obj.MAIL_ID exist
			obj.uniqueMails = counts[mailId];
		});

		return { uniqueObjects: Object.values(uniqueObjects), occurrences };
	};

	const { uniqueObjects } = countMailIdOccurrences(rowdata);

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const uniqueObjectsWithFlags = uniqueObjects?.filter((d) => {
		return COUNTRY_CODES[d?.COUNTRY] === undefined
			? d
			: COUNTRY_CODES[d?.COUNTRY];
	});

	useEffect(() => {
		if (mailid && gridApi && !searchValue) {
			setView('mpn-view');
			const getMpnColumn = gridApi?.getFilterInstance('INTERNET_MESSAGE_ID');

			getMpnColumn?.setModel({ values: [mailid] });
			gridApi?.onFilterChanged();

			setTimeout(() => {
				gridApi.getSelectedRows()?.length > 0
					? gridApi.getSelectedRows()
					: gridApi?.selectAll();
				gridApi.copySelectedRowsToClipboard({
					includeHeaders: true,
					columnKeys: ['MPN', 'BRAND', 'QTY'],
				});
				gridApi.deselectAll();
				message.open({
					type: 'success',
					content: 'Results copied to your clipboard!',
				});
			}, 1000);
		} //eslint-disable-next-line
	}, [mailid, gridApi, searchValue]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="RFQ Opportunities"
				route2="Customer RFQs"
				icon={<BranchesOutlined style={{ paddingRight: '5px' }} />}
			/>
			<SectionInfo
				title="Customer RFQs"
				info="This section displays all the RFQs received by the Customer.​"
				tooltipTitle={
					<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
						<li>
							Generic domain email are not eligible Example: google.com,
							yahoo.com
						</li>
						<li>
							Subject will be excluded which contains excluded keyword and will
							ignore excluded subject
						</li>
						<li>Any attachment other than excel is ignored</li>
						<li>Ignore any mail legal disclaimers, confidentiality notes</li>
						<li>If any mail coming from excluded mail then its ignored</li>
						<li>Internal mails are also ignored</li>
					</ul>
				}
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				copyToClipboard={
					mailid && gridApi && !searchValue && copyResultsToClipboard
				}
				copyToClipboardText="Copy results to clipboard"
				updatePage
				onUpdate={searchValue ? handleSearch : fetchSupplierPricesToday}
				updateText="Check for new RFQs"
				lastReport={currentTime}
				lastReportText="Last updated on"
				rightSideChildren={
					<Radio.Group
						value={view}
						onChange={(e) => {
							setView(e.target.value);
							gridApi?.autoSizeAllColumns();
							gridApi.setFilterModel(null);
							gridApi?.setQuickFilter(null);
							setGlobalFilterValue(null);
						}}
					>
						<Radio value={'rfq-view'}>RFQ View</Radio>
						<Radio value={'mpn-view'}>MPN View</Radio>
					</Radio.Group>
				}
			/>
			<div className={styles['customer-prices']}>
				{count > 0 && searchValue.length < 0 && (
					<NotificationBar
						message={
							<p>
								{count}&nbsp;new RFQs received. Since your last refresh. Click
								on&nbsp;
								<b>Check for new RFQs</b> to see new RFQs
							</p>
						}
						greenBar
					/>
				)}

				<div className={styles['dropdown-wrapper']}>
					<div className={styles['input-button-wrapper']}>
						<Input
							style={{ width: '250px' }}
							placeholder="Global Search within Grid Results"
							onChange={(e) => {
								gridApi?.setQuickFilter(e.target.value);
								setGlobalFilterValue(e.target.value);
							}}
							allowClear={true}
							disabled={loading}
							value={globalFilterValue}
						/>
						<Input
							style={{ width: '250px' }}
							placeholder="Search by MPN"
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							onPressEnter={handleSearch}
							id="searchValue"
							allowClear={true}
							disabled={loading}
						/>
						<Button onClick={handleSearch} disabled={searchValue?.length <= 3}>
							Search <HiOutlineSearch className={styles['search-icon']} />
						</Button>

						<Button
							disabled={loading}
							onClick={() => setShowBorders((prev) => !prev)}
						>
							{showBorders ? 'Hide Borders' : 'Show Borders'}
						</Button>

						<Select
							title="Date Range:"
							disabled={loading}
							style={{
								width: 100,
							}}
							defaultValue={dateRange}
							placeholder="Date Range"
							options={[
								{ value: '1W', label: '1 Week' },
								{ value: '2W', label: '2 Weeks' },
								{ value: '3W', label: '3 Weeks' },
								{ value: '4W', label: '4 Weeks' },
								{ value: '', label: 'All' },
							]}
							onChange={(value) => setDateRange(value)}
						/>

						<Select
							title="Drill Down by:"
							disabled={loading}
							style={{
								width: 230,
							}}
							defaultValue={showLeaderCrm}
							options={[
								{ value: 'leads', label: 'Show leads only' },
								{ value: 'crm', label: 'Show CRM accounts only' },
								{ value: '', label: 'Show leads and CRM accounts' },
							]}
							onChange={(value) => setShowLeadorCrm(value)}
						/>
					</div>
					{selectedRows?.length > 0 && (
						<Button onClick={copyToClipboard}>Copy Row(s)</Button>
					)}
					{selectedRows?.length === 1 && (
						<Button disabled>Copy to Cost Book</Button>
					)}
				</div>

				{!searchValue?.length <= 3 && !rowdata && !error && !loading && (
					<div className={styles['no-data-wrapper']}>
						<NoData search message="Please Search an MPN to Continue" />
					</div>
				)}

				{loading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Fetching the Results..." />
					</div>
				)}

				{error && !loading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(rowdata) &&
					rowdata !== null &&
					!error &&
					!loading && (
						<div className={styles['no-data-wrapper']}>
							<NoData
								message={
									<>
										<p style={{ fontSize: '20px', marginBottom: '5px' }}>
											Oops... we didn't find anything that matched this search.
										</p>
										<p style={{ fontSize: '12px' }}>
											Try shaving off characters in your search descriptor to
											expand your search results.
										</p>
									</>
								}
							/>
						</div>
					)}

				{!loading && !error && isNotEmptyArray(rowdata) && (
					<div className={styles['table-wrapper']}>
						<div className={`ag-theme-custom ${styles['table']}`}>
							<AgGridReact
								ref={gridRef}
								onGridReady={onGridReady}
								rowSelection={'multiple'}
								rowData={view === 'rfq-view' ? uniqueObjectsWithFlags : rowdata}
								columnDefs={view === 'rfq-view' ? rfqColumns : mpnColumns}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								context={context}
								enableRangeSelection={true}
								excelStyles={excelStyles}
								suppressRowHoverHighlight={true}
								rowClass={styles['row-style']}
								suppressRowClickSelection={true}
								processCellForClipboard={processCellForClipboard}
								paginationPageSizeSelector={false}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CustomerPrices;
