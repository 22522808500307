import { createContext, useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';

import EdenContext from '../edenContext';
import { API_BASE_URL } from '../../config/baseURLs';

const LeadsExclusionContext = createContext();

export const LeadsExclusionProvider = ({ children }) => {
	const { userMail } = useContext(EdenContext);

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			if (obj.id) {
				const currentId = obj.id;
				const existingObj = uniqueIdsMap.get(currentId);

				if (
					!existingObj ||
					new Date(obj.updatedAt) > new Date(existingObj.updatedAt)
				) {
					uniqueIdsMap.set(currentId, obj);
				}
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.get(`${API_BASE_URL}/admin/leads-exclusion`)
			.then((response) => {
				setData(response.data?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onCellValueChanged = useCallback((e) => {
		const { id, type, parameter } = e.data;

		const formFields = {
			type: type ? type : '',
			parameter: parameter ? parameter : '',
			addedBy: userMail,
		};

		axios({
			method: id ? 'put' : 'post',
			url: id
				? `${API_BASE_URL}/admin/leads-exclusion/${id}`
				: `${API_BASE_URL}/admin/leads-exclusion`,
			data: formFields,
		})
			.then((response) => {
				setData((prev) =>
					filterUniqueIdsAndRecent([...prev, response.data?.data])
				);
				const column = e.column.colDef.field;

				e.api.refreshCells({
					force: true,
					columns: [column],
					rowNodes: [e.node],
				});
				message.success(response.data?.message);
			})
			.catch((err) => {
				message.error(err);
			}); // eslint-disable-next-line
	}, []);

	return (
		<LeadsExclusionContext.Provider
			value={{
				getData,
				data,
				setData,
				isLoading,
				error,
				filterUniqueIdsAndRecent,
				onCellValueChanged,
			}}
		>
			{children}
		</LeadsExclusionContext.Provider>
	);
};

export default LeadsExclusionContext;
