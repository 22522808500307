import { memo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Progress, Tag } from 'antd';
import { MdMail } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import MarkAsRFQ from '../pages/myRFQOpportunities/RFQByAccount/MarkAsRFQ';
import Action from '../pages/mySources/ChipFinderRuns/Action';
import KeywordActions from '../pages/admin/ChatGptKeywords/KeywordActions';
import EEAccExcluAction from '../pages/admin/EEAccExcluList/EEAccExcluAction';
import EPAccTagsAction from '../pages/admin/EPAccTags/EPAccTagsAction';
import SourcingAssignmentAction from '../pages/admin/SourcingAssignments/SourcingAssignmentsAction';
import ToolTip from '../components/tooltip';
import { capitalizeFirstLetter } from '../utils';
import { CountryCellRenderer } from './countryCellRenderer';
import Sheet from '../images/sheets.png';
import MailDetails from '../pages/myEmailEngagement/CusContEngagementV2/Maildetails';
import {
	decimalFilterParams,
	dollarDecimalFilterParams,
	numberFilterParams,
} from '../filters';

import styles from './columns.module.scss';

export const COUNTRY_CODES = {
	Andorra: 'ad',
	'United Arab Emirates': 'ae',
	Afghanistan: 'af',
	'Antigua and Barbuda': 'ag',
	Anguilla: 'ai',
	Albania: 'al',
	Armenia: 'am',
	Angola: 'ao',
	Antarctica: 'aq',
	Argentina: 'ar',
	'American Samoa': 'as',
	Austria: 'at',
	Australia: 'au',
	Aruba: 'aw',
	'Aland Islands': 'ax',
	Azerbaijan: 'az',
	'Bosnia and Herzegovina': 'ba',
	Barbados: 'bb',
	Bangladesh: 'bd',
	Belgium: 'be',
	'Burkina Faso': 'bf',
	Bulgaria: 'bg',
	Bahrain: 'bh',
	Burundi: 'bi',
	Benin: 'bj',
	'Saint Barthelemy': 'bl',
	Bermuda: 'bm',
	Brunei: 'bn',
	Bolivia: 'bo',
	'Caribbean Netherlands': 'bq',
	'Bonaire Saint Eustatius and Saba': 'bq',
	Brazil: 'br',
	Bahamas: 'bs',
	Bhutan: 'bt',
	'Bouvet Island': 'bv',
	Botswana: 'bw',
	Belarus: 'by',
	Belize: 'bz',
	Canada: 'ca',
	'Cocos (Keeling) Islands': 'cc',
	'Cocos Islands': 'cc',
	'DR Congo': 'cd',
	'Democratic Republic of the Congo': 'cd',
	'Central African Republic': 'cf',
	'Republic of the Congo': 'cg',
	Congo: 'cg',
	Switzerland: 'ch',
	"Côte d'Ivoire (Ivory Coast)": 'ci',
	'Cook Islands': 'ck',
	Chile: 'cl',
	Cameroon: 'cm',
	China: 'cn',
	Colombia: 'co',
	'Costa Rica': 'cr',
	Cuba: 'cu',
	'Cape Verde': 'cv',
	Curacao: 'cw',
	'Christmas Island': 'cx',
	Cyprus: 'cy',
	'Czech Republic': 'cz',
	Germany: 'de',
	Djibouti: 'dj',
	Denmark: 'dk',
	Dominica: 'dm',
	'Dominican Republic': 'do',
	Algeria: 'dz',
	Ecuador: 'ec',
	Estonia: 'ee',
	Egypt: 'eg',
	'Western Sahara': 'eh',
	Eritrea: 'er',
	Spain: 'es',
	Ethiopia: 'et',
	Finland: 'fi',
	Fiji: 'fj',
	'Falkland Islands': 'fk',
	Micronesia: 'fm',
	'Faroe Islands': 'fo',
	France: 'fr',
	Gabon: 'ga',
	'Great Britain': 'gb',
	Grenada: 'gd',
	Georgia: 'ge',
	'French Guiana': 'gf',
	Guernsey: 'gg',
	Ghana: 'gh',
	Gibraltar: 'gi',
	Greenland: 'gl',
	Gambia: 'gm',
	Guinea: 'gn',
	Guadeloupe: 'gp',
	'Equatorial Guinea': 'gq',
	Greece: 'gr',
	'South Georgia': 'gs',
	'South Georgia and the South Sandwich Islands': 'gs',
	Guatemala: 'gt',
	Guam: 'gu',
	'Guinea-Bissau': 'gw',
	Guyana: 'gy',
	'Hong Kong': 'hk',
	'Heard Island and McDonald Islands': 'hm',
	Honduras: 'hn',
	Croatia: 'hr',
	Haiti: 'ht',
	Hungary: 'hu',
	Indonesia: 'id',
	Ireland: 'ie',
	'Ireland (IRL)': 'ie',
	Israel: 'il',
	'Isle of Man': 'im',
	India: 'in',
	'British Indian Ocean Territory': 'io',
	Iraq: 'iq',
	Iran: 'ir',
	Iceland: 'is',
	Italy: 'it',
	Jersey: 'je',
	Jamaica: 'jm',
	Jordan: 'jo',
	Japan: 'jp',
	Kenya: 'ke',
	Kyrgyzstan: 'kg',
	Cambodia: 'kh',
	Kiribati: 'ki',
	Comoros: 'km',
	'Saint Kitts and Nevis': 'kn',
	'North Korea': 'kp',
	'South Korea': 'kr',
	Kuwait: 'kw',
	'Cayman Islands': 'ky',
	Kazakhstan: 'kz',
	Laos: 'la',
	Lebanon: 'lb',
	'Saint Lucia': 'lc',
	Liechtenstein: 'li',
	'Sri Lanka': 'lk',
	Liberia: 'lr',
	Lesotho: 'ls',
	Lithuania: 'lt',
	Luxembourg: 'lu',
	Latvia: 'lv',
	Libya: 'ly',
	Morocco: 'ma',
	Monaco: 'mc',
	Moldova: 'md',
	Montenegro: 'me',
	'Saint Martin': 'mf',
	Madagascar: 'mg',
	'Marshall Islands': 'mh',
	Macedonia: 'mk',
	Mali: 'ml',
	Myanmar: 'mm',
	Mongolia: 'mn',
	Macau: 'mo',
	'Northern Mariana Islands': 'mp',
	Martinique: 'mq',
	Mauritania: 'mr',
	Montserrat: 'ms',
	Malta: 'mt',
	Mauritius: 'mu',
	Maldives: 'mv',
	Malawi: 'mw',
	Mexico: 'mx',
	Malaysia: 'my',
	Mozambique: 'mz',
	Namibia: 'na',
	'New Caledonia': 'nc',
	Niger: 'ne',
	'Norfolk Island': 'nf',
	Nigeria: 'ng',
	Nicaragua: 'ni',
	Netherlands: 'nl',
	Norway: 'no',
	Nepal: 'np',
	Nauru: 'nr',
	Niue: 'nu',
	'New Zealand': 'nz',
	Oman: 'om',
	Panama: 'pa',
	Peru: 'pe',
	'French Polynesia': 'pf',
	'Papua New Guinea': 'pg',
	Philippines: 'ph',
	Pakistan: 'pk',
	Poland: 'pl',
	'Saint Pierre and Miquelon': 'pm',
	'Pitcairn Islands': 'pn',
	Pitcairn: 'pn',
	'Puerto Rico': 'pr',
	Palestine: 'ps',
	'Palestinian Territory': 'ps',
	Portugal: 'pt',
	Palau: 'pw',
	Paraguay: 'py',
	Qatar: 'qa',
	Réunion: 're',
	Romania: 'ro',
	Serbia: 'rs',
	Russia: 'ru',
	Rwanda: 'rw',
	'Saudi Arabia': 'sa',
	'Solomon Islands': 'sb',
	Seychelles: 'sc',
	Sudan: 'sd',
	Sweden: 'se',
	Singapore: 'sg',
	'Saint Helena, Ascension and Tristan da Cunha': 'sh',
	'Saint Helena': 'sh',
	Slovenia: 'si',
	'Svalbard and Jan Mayen': 'sj',
	Slovakia: 'sk',
	'Sierra Leone': 'sl',
	'San Marino': 'sm',
	Senegal: 'sn',
	Somalia: 'so',
	Suriname: 'sr',
	'South Sudan': 'ss',
	'Sao Tome and Principe': 'st',
	'El Salvador': 'sv',
	'Sint Maarten': 'sx',
	Syria: 'sy',
	'Eswatini (Swaziland)': 'sz',
	Swaziland: 'sz',
	'Turks and Caicos Islands': 'tc',
	Chad: 'td',
	'French Southern and Antarctic Lands': 'tf',
	'French Southern Territories': 'tf',
	Togo: 'tg',
	Thailand: 'th',
	Tajikistan: 'tj',
	Tokelau: 'tk',
	'Timor-Leste': 'tl',
	Turkmenistan: 'tm',
	Tunisia: 'tn',
	Tonga: 'to',
	Turkey: 'tr',
	'Trinidad and Tobago': 'tt',
	Tuvalu: 'tv',
	'Chinese Taipei': 'tw',
	Taiwan: 'tw',
	Tanzania: 'tz',
	Ukraine: 'ua',
	Uganda: 'ug',
	'United States Minor Outlying Islands': 'um',
	'United States of America': 'us',
	'United States': 'us',
	'Virgin Islands- U.S.': 'us',
	'United Kingdom': 'gb',
	Uruguay: 'uy',
	Uzbekistan: 'uz',
	'Vatican City (Holy See)': 'va',
	'Vatican City State (Holy See)': 'va',
	Vatican: 'va',
	'Saint Vincent and the Grenadines': 'vc',
	Venezuela: 've',
	'British Virgin Islands': 'vg',
	'United States Virgin Islands': 'vi',
	'U.S. Virgin Islands': 'vi',
	Vietnam: 'vn',
	Vanuatu: 'vu',
	'Wallis and Futuna': 'wf',
	Samoa: 'ws',
	Kosovo: 'xk',
	Yemen: 'ye',
	Mayotte: 'yt',
	'South Africa': 'za',
	Zambia: 'zm',
	Zimbabwe: 'zw',
};

export const customerReportColumns = [
	{
		field: 'SalesRep',
		headerName: 'SalesRep',
		cellRenderer: (p) => p.value && capitalizeFirstLetter(p.value),
		minWidth: 150,
		headerTooltip: 'The Sales Rep who handles this account',
	},
	{
		field: 'Customer',
		headerName: 'Customer',
		cellRenderer: (p) => p.value && p.value,
		minWidth: 120,
		headerTooltip: 'Name of the customer account from the CRM',
	},
	{
		field: 'CompanyType',
		headerName: 'Company Type',
		cellRenderer: (p) => p.value && p.value,
		minWidth: 150,
		headerTooltip: 'Type of customer as listed in the CRM',
	},
	{
		field: 'Country',
		headerName: 'Country',
		cellRenderer: CountryCellRenderer,
		filter: 'agSetColumnFilter',
		filterParams: {
			cellRenderer: CountryCellRenderer,
			cellRendererParams: { isFilterRenderer: true },
		},
		minWidth: 150,
		headerTooltip: 'Location of the customer account from the CRM',
	},
	{
		field: 'FyPrevious',
		cellClass: 'numberType',
		headerName: `FY 2024`,
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 150,
		headerTooltip: 'FY Billings accounted for in the previous FY',
		aggFunc: 'sum',
		sort: 'desc',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'FyCurrent',
		cellClass: 'numberType',
		headerName: `FY 2025`,
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 150,
		headerTooltip: 'FY Billings accounted for in the current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'PercentOfTotalBillings',
		cellClass: 'decimal',
		headerName: '% of Total Billings',
		cellRenderer: (p) => p.value && p.value?.toLocaleString('en-US'),
		minWidth: 130,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		headerTooltip:
			'Contribution from account to the total revenue in current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: decimalFilterParams,
	},
	{
		field: 'FyQ1',
		cellClass: 'numberType',
		headerName: 'FY Q1',
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 110,
		headerTooltip: 'Q1 billings for current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'FyQ2',
		cellClass: 'numberType',
		headerName: 'FY Q2',
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 110,
		headerTooltip: 'Q2 billings for current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'FyQ3',
		cellClass: 'numberType',
		headerName: 'FY Q3',
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 110,
		headerTooltip: 'Q3 billings for current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'FyQ4',
		cellClass: 'numberType',
		headerName: 'FY Q4',
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 110,
		headerTooltip: 'Q4 billings for current FY',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'Backorder',
		cellClass: 'numberType',
		headerName: 'Backorder',
		cellRenderer: (p) => p.value && `$ ${p.value?.toLocaleString('en-US')}`,
		minWidth: 150,
		headerTooltip: 'Total backorder value for this account',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: dollarDecimalFilterParams,
	},
	{
		field: 'GPM',
		cellClass: 'decimal',
		headerName: 'GPM',
		cellRenderer: (p) => p.value && p.value?.toFixed(2),
		minWidth: 90,
		headerTooltip: 'Median average mark-up points.',
		aggFunc: 'sum',
		filter: 'agSetColumnFilter',
		filterParams: decimalFilterParams,
	},
	{
		field: 'RfqTotal',
		headerName: ' RFQ Total',
		cellRenderer: (p) => (
			<>{p.value ? p.value?.toLocaleString('en-US') : '--'}</>
		),
		minWidth: 90,
		headerTooltip: 'Total # of RFQs received in the reported date range',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		hide: true,
		aggFunc: 'sum',
	},
	{
		field: 'RfqQ1',
		headerName: 'RFQ Q1',
		cellRenderer: (p) => (
			<>{p.value ? p.value?.toLocaleString('en-US') : '--'}</>
		),
		minWidth: 80,
		headerTooltip: 'Total # of RFQs received in Q1',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		hide: true,
		aggFunc: 'sum',
	},
	{
		field: 'RfqQ2',
		headerName: 'RFQ Q2',
		cellRenderer: (p) => (
			<>{p.value ? p.value?.toLocaleString('en-US') : '--'}</>
		),
		minWidth: 80,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		headerTooltip: 'Total # of RFQs received in Q2',
		hide: true,
		aggFunc: 'sum',
	},
	{
		field: 'RfqQ3',
		headerName: 'RFQ Q3',
		cellRenderer: (p) => (
			<>{p.value ? p.value?.toLocaleString('en-US') : '--'}</>
		),
		minWidth: 80,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		headerTooltip: 'Total # of RFQs received in Q3',
		hide: true,
		aggFunc: 'sum',
	},
	{
		field: 'RfqQ4',
		headerName: 'RFQ Q4',
		cellRenderer: (p) => (
			<>{p.value ? p.value?.toLocaleString('en-US') : '--'}</>
		),
		minWidth: 80,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		headerTooltip: 'Total # of RFQs received in Q4',
		hide: true,
		aggFunc: 'sum',
	},
];

export const customerContactColumnsV2 = [
	{
		headerName: 'Account Details',
		children: [
			{
				field: 'AcctSalesRep',
				headerName: 'Sales Rep',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 130,
				headerTooltip: 'Sales Rep assigned to an account.',
				// filter: 'agSetColumnFilter',
			},
			{
				field: 'NoOfContacts',
				headerName: '# of Contacts',
				filter: 'agNumberColumnFilter',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				headerTooltip: 'Total number of contacts in each account',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 115,
			},
			{
				field: 'ContactName',
				headerName: 'Contact',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				headerTooltip: 'Name of the contact',
				minWidth: 100,
			},
			{
				field: 'ContactEmail',
				headerName: 'Email',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				headerTooltip: 'Email of the contact',
				minWidth: 100,
			},
			{
				field: 'CompanyType',
				headerName: 'Type',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				headerTooltip: 'Type of the customer accounts as mentioned in the CRM',
				minWidth: 100,
			},
			{
				field: 'AcctCountry',
				headerName: 'Country',
				cellRenderer: CountryCellRenderer,
				filter: 'agSetColumnFilter',
				filterParams: {
					cellRenderer: CountryCellRenderer,
					cellRendererParams: { isFilterRenderer: true },
				},
				headerTooltip: 'Country in which the customer account is located',
				minWidth: 100,
			},
			{
				field: 'FyBilled',
				headerName: 'FY Billed',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? `$${p.value?.toLocaleString('en-US')}` : '--'}
					</span>
				),
				headerTooltip: 'FY Billings for last 12 months',
				minWidth: 100,
				filter: 'agNumberColumnFilter',
				sort: 'desc',
			},
			// {
			// 	field: 'FyBillRank',
			// 	headerName: 'Bill Rank',
			// 	cellRenderer: (p) => (
			// 		<span className={styles['font-weight']}>{p.value}</span>
			// 	),
			// 	headerTooltip: 'Rank based on billings in the current FY',
			// 	minWidth: 100,
			// 	filter: 'agSetColumnFilter',
			// 	filterParams: numberFilterParams,
			// },
		],
	},
	{
		headerName: 'Conversation Statistics',
		children: [
			{
				field: 'CS_TotalIncomingEmails',
				headerName: `# of Incoming Emails`,
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>{p.value}</span>
				),
				hide: false,
				headerTooltip: `# of incoming emails from the client.`,
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
			},
			{
				field: 'CS_Total1wayInbounds',
				headerName: `# of 1-way Inbound`,
				cellRenderer: memo(MailDetails),
				hide: false,
				headerTooltip: `They email, but we don't respond.`,
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
				cellStyle: { cursor: 'pointer' },
			},
			{
				field: 'CS_Total1wayInboundsRFQ',
				headerName: `1-way Inbound w/ RFQs`,
				cellRenderer: memo(MailDetails),
				hide: false,
				headerTooltip: `Emails containing RFQs`,
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
				cellStyle: { cursor: 'pointer' },
			},
		],
	},
	{
		headerName: 'Last Outgoing Emails',
		children: [
			{
				field: 'LO_LastSentOutNoDaysAgo',
				headerName: '# Days Ago',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>{p.value}</span>
				),
				hide: false,
				headerTooltip: '# of days before the most recent outgoing email',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 100,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
			},
			{
				field: 'LO_WhoSent',
				headerName: 'Who sent?',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				hide: false,
				headerTooltip: 'Sender of this most recent outgoing email',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 100,
			},
		],
	},
	{
		headerName: 'Last Incoming Emails',
		children: [
			{
				field: 'LI_ReceivedNoDaysAgo',
				headerName: '# Days Ago',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>{p.value}</span>
				),
				hide: false,
				headerTooltip:
					'# of days before the most recent incoming email was received by any SCI employee',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
			},
			{
				field: 'LI_ExternalSender',
				headerName: 'Last Contact Person',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				hide: false,
				headerTooltip:
					'Contact person from CRM who sent the most recent incoming email',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
			},
			{
				field: 'LI_NoDaysAgoToMe',
				headerName: '# of Days Ago to Me',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value === 0 ? 0 : p.value > 0 ? p.value : '--'}
					</span>
				),
				hide: false,
				headerTooltip:
					'# of days before the most recent incoming email was directed to you',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 140,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
			},
			{
				field: 'LI_NoDaysAgoToMyColleague',
				headerName: '# of Days Ago to My Colleague',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value === 0 ? 0 : p.value > 0 ? p.value : '--'}
					</span>
				),
				hide: false,
				headerTooltip:
					'# of days before the most recent incoming email was directed to someone in SCI, apart from you',
				wrapHeaderText: true,
				autoHeaderHeight: true,
				minWidth: 160,
				filter: 'agSetColumnFilter',
				filterParams: numberFilterParams,
			},
			{
				field: 'LI_WhichColleague',
				headerName: 'Which colleague?',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				hide: false,
				headerTooltip: 'Recipient of this most recent incoming email',
				minWidth: 200,
			},
		],
	},
];

export const rfqByAccountsColumns = [
	{
		headerName: 'General',
		children: [
			// {
			// 	field: 'actionCenter',
			// 	headerName: 'ActionCenter',
			// 	// checkboxSelection: true,
			// 	// headerCheckboxSelection: true,
			// 	cellRenderer: memo(ActionCenter),
			// 	minWidth: 170,
			// },
			{
				field: 'RFQID',
				headerName: 'RFQ ID',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				pinned: 'left',
				lockPinned: true,
				width: 95,
			},
		],
	},
	{
		headerName: 'Customer Info',
		children: [
			{
				field: 'SalesRep',
				headerName: 'Sales Rep',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 110,
				hide: false,
			},
			{
				field: 'CustomerAccount',
				headerName: 'Customer',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 115,
			},
			{
				field: 'ContactName',
				headerName: 'Contact',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 100,
			},
			{
				field: 'EmailAddress',
				headerName: 'Email',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 90,
			},
			{
				field: 'CCedContacts',
				headerName: 'CC-ed Contacts',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 150,
			},
			{
				field: 'type',
				headerName: 'Type',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 90,
			},
		],
	},
	{
		headerName: 'RFQ Details',
		children: [
			{
				field: 'RFQDateTime',
				headerName: 'RFQ Date/Time',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 150,
			},
			{
				field: 'noofline',
				headerName: '# of Lines',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 115,
			},
			{
				field: 'SubjectLine',
				headerName: 'Subject Line',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 130,
			},
			{
				field: 'RFQType',
				headerName: 'RFQ Type',
				hide: true,
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 110,
			},
			{
				field: 'RFQFoundIn',
				headerName: 'RFQ Found In?',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 145,
			},
		],
	},
	{
		headerName: 'RFQ Lines Info',
		children: [
			{
				field: 'MPN',
				headerName: 'MPN',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 85,
			},
			{
				field: 'MFRBrand',
				headerName: 'MFR/Brand',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 125,
			},
			{
				field: 'QtyReq',
				headerName: 'Qty Req',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 105,
			},
			{
				field: 'TargetPrice',
				headerName: 'TP',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? `$${p.value}` : '--'}
					</span>
				),
				minWidth: 90,
			},
			{
				field: 'cfcount',
				headerName: 'CF Count',
				cellRenderer: (p) => (
					<span className={styles['font-weight']}>
						{p.value ? p.value : '--'}
					</span>
				),
				minWidth: 110,
			},
			{
				field: 'ChipFinderStatus',
				headerName: 'ChipFinder Status?',
				cellStyle: {
					display: 'flex',
					alignItems: 'center',
				},
				cellRenderer: (p) => (
					<div style={{ width: '150px' }}>
						{p.value ? (
							<div className={styles['chipfinder-status']}>
								<Progress
									status={p.value < 100 ? 'active' : ''}
									percent={p.value}
									size="small"
									className={styles['chipfinder-progress']}
								/>
								{p.value >= 100 ? (
									<ToolTip title="Download BOM">
										<img src={Sheet} className={styles['sheet']} alt="Sheet" />
									</ToolTip>
								) : (
									<ToolTip title="Running">
										<AiOutlineInfoCircle
											className={styles['chipfinder-tooltip-status']}
										/>
									</ToolTip>
								)}
							</div>
						) : (
							'--'
						)}
					</div>
				),
				minWidth: 190,
			},
		],
	},
];

export const rfqByAccountsOtherEmailsColumns = [
	{
		field: 'sno',
		headerName: 'S.No.',
		maxWidth: 90,
		cellStyle: { textAlign: 'center' },
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
		),
	},
	{
		field: 'receiveddatatime',
		headerName: 'Received Date/Time',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'emailSender',
		headerName: 'Email Sender',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'ccedContacts',
		headerName: 'CCed Contacts',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'crmAccount',
		headerName: 'CRM Account',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'subjectLine',
		headerName: 'Subject Line',
		minWidth: 50,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'previewEmail',
		headerName: 'Preview Email',
		maxWidth: 140,
		cellRenderer: (p) => (
			<ToolTip title="Preview Mail">
				<MdMail className={styles['mail']} />
			</ToolTip>
		),
	},
	{
		field: 'markAsRfq',
		headerName: 'Mark as RFQ',
		cellRenderer: memo(MarkAsRFQ),
	},
];

export const customerProespectColumns = [
	{
		checkboxSelection: true,
		field: 'subject',
		headerName: 'Subject Line',
	},
	// {
	// 	field: 'sciCustomer',
	// 	headerName: 'Customer',
	// 	hide: true,
	// },
	{
		field: 'from',
		headerName: 'From',
	},
	{
		field: 'sciSalesRep',
		headerName: 'SCI Rep',
	},
	{
		field: 'receivedDateTime',
		headerName: 'ReceivedDate',
		sort: 'desc',
		cellRenderer: (p) => (
			<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
		),
	},

	{
		field: 'email',
		headerName: 'Email',
		cellRenderer: (p) => (
			<Link target="_blank" to={p?.data?.webLink}>
				<ToolTip title="Preview mail">
					<MdMail style={{ fontSize: '16px' }} />
				</ToolTip>
			</Link>
		),
		cellStyle: { padding: '4px 0 0 25px' },
		maxWidth: 80,
	},
];

export const chipFinderRunsColumns = [
	{
		field: 'SCIrepresentative',
		headerName: 'SCI Representative',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'Filename',
		headerName: 'Filename',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'uplodaddatatime',
		headerName: 'Upload Date/Time',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
		sort: 'desc',
	},
	{
		field: 'bomid',
		headerName: 'BomID',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'oflines',
		headerName: '# of Lines',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'status',
		headerName: 'Status',
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		cellRenderer: (p) => (
			<div style={{ width: '150px' }}>
				{p.value ? (
					<div className={styles['chipfinder-status']}>
						<Progress
							status={p.value < 100 && p.data?.action !== true ? 'active' : ''}
							percent={p.value}
							size="small"
							className={styles['chipfinder-progress']}
							strokeColor={p.data?.action !== true ? '' : '#C51605'}
							trailColor={p.data?.action !== true ? '' : '#EEEDED'}
						/>
						{p.value >= 100 ? (
							<ToolTip title="Download BOM">
								<img src={Sheet} className={styles['sheet']} alt="Sheet" />
							</ToolTip>
						) : (
							<ToolTip title={p.data?.action !== true ? 'Running' : 'Stopped'}>
								<AiOutlineInfoCircle
									className={styles['chipfinder-tooltip-status']}
								/>
							</ToolTip>
						)}
					</div>
				) : (
					'--'
				)}
			</div>
		),
		minWidth: 190,
	},
	{
		field: 'estcompdatatime',
		headerName: 'Est. Comp. Date/Time',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
		minWidth: 200,
	},
	{
		field: 'action',
		headerName: 'Action',
		cellRenderer: memo(Action),
		cellStyle: {
			paddingLeft: '40px',
		},
	},
];

export const backorderSitesColumn = [
	{
		field: 'sno',
		headerName: 'S No.',
		maxWidth: 60,
		cellStyle: { textAlign: 'center' },
		cellRenderer: (p) => p.rowIndex + 1,
	},
	{
		field: 'type',
		headerName: 'Type',
		maxWidth: 100,
	},
	{
		field: 'name',
		headerName: 'Name',
		maxWidth: 150,
	},
	{
		field: 'hyperlink',
		headerName: 'Hyperlink',
		cellRenderer: (p) => (
			<Link target="_blank" to={p.value} className={styles['link']}>
				{p.value}
			</Link>
		),
	},
];

export const chatgptKeywordsColumns = [
	{
		field: 'sno',
		headerName: 'S.No.',
		maxWidth: 90,
		cellStyle: { paddingLeft: '20px' },
		floatingFilter: false,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
		),
	},
	{
		field: 'TYPE',
		headerName: 'Type',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'KEYWORD',
		headerName: 'Keyword',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'DATE_ADD',
		headerName: 'Date Added',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>
				{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}
			</span>
		),
	},
	{
		field: 'action',
		headerName: 'Actions',
		floatingFilter: false,
		cellRenderer: memo(KeywordActions),
		maxWidth: 90,
		cellStyle: { padding: '4px 0 0 10px' },
	},
];

export const eeAccExcluListColumns = [
	{
		field: 'sno',
		headerName: 'S.No.',
		maxWidth: 90,
		cellStyle: { paddingLeft: '20px' },
		floatingFilter: false,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
		),
	},
	{
		field: 'EMAIL',
		headerName: 'Emails to Exclude from Eagle',
	},
	{
		field: 'DATE_ADDED',
		headerName: 'Date Added',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>
				{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}
			</span>
		),
	},
	{
		field: 'action',
		headerName: 'Actions',
		floatingFilter: false,
		cellRenderer: memo(EEAccExcluAction),
		maxWidth: 90,
		cellStyle: { padding: '4px 0 0 10px' },
	},
];

export const epAccTagsColumns = [
	{
		field: 'sno',
		headerName: 'S.No.',
		maxWidth: 60,
		cellStyle: { paddingLeft: '20px' },
		floatingFilter: false,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
		),
	},
	{
		field: 'crm_account',
		headerName: 'CRM Account',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'contact_email',
		headerName: 'Contact Email',
		wrapText: true,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		minWidth: 250,
		autoHeight: true,
		cellRenderer: (p) => (
			<>
				{p?.value === 'undefined' || p?.value === '' ? (
					'--'
				) : (
					<>
						{JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
						))}
					</>
				)}
			</>
		),
	},
	{
		field: 'applied_at_account_level',
		headerName: 'Applied at account level?',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'rfq_location',
		headerName: 'RFQ Location',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'keyword_tags',
		headerName: 'Keyword Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'mpn_header_tags',
		headerName: 'MPN HeaderTags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'brand_header_name_tags',
		headerName: 'Brand Header Name Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'qty_header_tags',
		headerName: 'QTY Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'dc_header_tags',
		headerName: 'DC Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => {
			return (
				<>
					{p.value
						? JSON.parse(p?.value)?.map((el, index) => (
								<Tag key={index} color="#2A83FF">
									{el}
								</Tag>
						  ))
						: ''}
				</>
			);
		},
	},
	{
		field: 'lt_header_tags',
		headerName: 'LT Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'prc_header_tags',
		headerName: 'Price Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'moq_header_tags',
		headerName: 'MOQ Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'spq_header_tags',
		headerName: 'SPQ Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'pkg_header_tags',
		headerName: 'PKG Header Tags',
		wrapHeaderText: true,
		autoHeaderHeight: true,
		wrapText: true,
		cellRenderer: (p) => (
			<>
				{p.value
					? JSON.parse(p?.value)?.map((el, index) => (
							<Tag key={index} color="#2A83FF">
								{el}
							</Tag>
					  ))
					: ''}
			</>
		),
	},
	{
		field: 'date_added',
		headerName: 'Date Added',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>
				{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}
			</span>
		),
	},
	{
		field: 'action',
		headerName: 'Actions',
		floatingFilter: false,
		suppressFilter: true,
		cellRenderer: memo(EPAccTagsAction),
		maxWidth: 90,
		cellStyle: { padding: '4px 0 0 10px' },
	},
];

export const sourcingAssignmentsColumns = [
	{
		field: 'sno',
		headerName: 'S.No.',
		maxWidth: 60,
		cellStyle: { paddingLeft: '20px' },
		floatingFilter: false,
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.rowIndex + 1}</span>
		),
	},
	{
		field: 'salesRep.salesRepName',
		headerName: 'Sales Rep',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'salesRep.salesRepEmail',
		headerName: 'Email',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	{
		field: 'sourcingSpecialistEmail',
		headerName: 'Sourcing Specialist',
		cellRenderer: (p) => (
			<span className={styles['font-weight']}>{p.value ? p.value : '--'}</span>
		),
	},
	// {
	// 	field: 'DATE_ADD',
	// 	headerName: 'Date Added',
	// 	maxWidth: 160,
	// 	cellRenderer: (p) => (
	// 		<span className={styles['font-weight']}>
	// 			{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}
	// 		</span>
	// 	),
	// },
	{
		field: 'action',
		headerName: 'Actions',
		floatingFilter: false,
		cellRenderer: memo(SourcingAssignmentAction),
		maxWidth: 90,
		cellStyle: { padding: '4px 0 0 10px' },
	},
];
