import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Avatar, Radio, message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { LoadingOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { IoMdAddCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { MdMail } from 'react-icons/md';

import BreadCrumb from '../../../components/breadcrumb';
import InnerHeader from '../../../components/innerHeader';
import EdenContext from '../../../context/edenContext';
import Button from '../../../components/button';
import NotificationBar from '../../../components/notificationBar';
import SectionInfo from '../../../components/sectionInfo';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import {
	filterUniqueIdsAndRecent,
	isNotEmptyArray,
	titleCase,
} from '../../../utils';
import AccessDenied from '../../../components/accessDenied';
import ToolTip from '../../../components/tooltip';
import CustomerProspect from './CustomerProspect';
import CFfileActions from './CFFileAction';
import MpnQueueAction from './MpnQueueAction';
import Drawer from '../../../components/drawer';
import { RadioTwo } from '../../../components/radio';
import { avatarBgColor } from '../../../constant';
import MpnQueueContext from '../../../context/mpnQueueContext';
import { dateFilterParams } from '../../../filters';

import styles from './mpnQueue.module.scss';

const suppressNavigation = (params) => {
	var KEY_A = 'A';
	var KEY_C = 'C';
	var KEY_V = 'V';
	var KEY_LEFT = 'ArrowLeft';
	var KEY_UP = 'ArrowUp';
	var KEY_RIGHT = 'ArrowRight';
	var KEY_DOWN = 'ArrowDown';
	var event = params.event;
	var key = event.key;
	var keysToSuppress = [];

	if (event.ctrlKey || event.metaKey) {
		keysToSuppress.push(KEY_A);
		keysToSuppress.push(KEY_V);
		keysToSuppress.push(KEY_C);
		keysToSuppress.push(KEY_LEFT);
		keysToSuppress.push(KEY_UP);
		keysToSuppress.push(KEY_RIGHT);
		keysToSuppress.push(KEY_DOWN);
	}

	var suppress = keysToSuppress.some(function (suppressedKey) {
		return suppressedKey === key || key.toUpperCase() === suppressedKey;
	});
	return suppress;
};

const suppressUpDownNavigation = (params) => {
	var key = params.event.key;
	return key === 'ArrowUp' || key === 'ArrowDown';
};

const MPNQueue = () => {
	const { userType, userMail, srData, ssData, crmAccounts, crmAccCusSupp } =
		useContext(EdenContext);
	const {
		mpnQueueList,
		mpnQueueLoading,
		mpnQueueError,
		sourcingMpnQueue,
		setMpnQueueList,
		mpnQueueRowData,
		setMpnQueueRowData,
		mpnQueueHttpStatusCode,
		createCustomerSummaryTable,
		createSalesRepSummaryTable,
	} = useContext(MpnQueueContext);

	const { messageURl } = useContext(EdenContext);

	const gridRef = useRef(null);

	const [gridApi, setGridApi] = useState();
	const [queueView, setQueueView] = useState('View Open Queue');
	const [drawerMail, setDrawerMail] = useState(false);
	const [drawerMailData, setDrawerMailData] = useState(null);
	const [drawerMailLoading, setDrawerMailLoading] = useState(false);
	const [cellData, setCellData] = useState(null);
	const [salesRepSumNoOfRfq, setSalesRepSumNoOfRfq] = useState([]);

	const customerSalesRepArray = crmAccCusSupp.filter(
		(el) => el.INACTIVE === 'Yes' || el.INACTIVE === 'No'
	);

	const closeDrawer = () => {
		setDrawerMail(!drawerMail);
		setDrawerMailData(null);
		setCellData(null);
	};

	const openDrawer = (cid) => {
		setDrawerMail(true);
		setDrawerMailLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=get_mail_html&cid=${cid}`
			)
			.then((response) => {
				setDrawerMailData(response.data);
			})
			.finally(() => {
				setDrawerMailLoading(false);
			});
	};

	const ssDetails = [
		...ssData,
		{
			salesRepCrmId: 1061,
			salesRepEmail: 'customerservice@supremecomponents.com',
			salesRepName: 'Mikee',
		},
		{
			salesRepCrmId: 1076,
			salesRepEmail: 'cindy@supremecomponents.com',
			salesRepName: 'Cindy',
		},
		{
			salesRepCrmId: 1117,
			salesRepEmail: 'support@supremecomponents.com',
			salesRepName: 'Gautam',
		},
		{
			salesRepCrmId: 1,
			salesRepEmail: 'Self',
			salesRepName: 'Self',
		},
	];

	const filterCRMAccount =
		Array.isArray(crmAccounts) &&
		crmAccounts?.filter((el) => el?.INACTIVE !== 'Yes');

	useEffect(() => {
		sourcingMpnQueue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columnDefs = [
		{ field: 'currentTime', hide: true, sort: 'desc' },
		{
			field: 'action',
			headerName: 'Action',
			floatingFilter: false,
			cellRenderer: memo(MpnQueueAction),
			maxWidth: 140,
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{
			field: 'id',
			headerName: 'ID',
			sortable: false,
			cellStyle: { paddingLeft: '20px' },
			cellClass: styles['gray-cell'],
			minWidth: 70,
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>{p.value}</span>
			),
		},
		{
			field: 'salesRepCrmName',
			headerName: 'Sales Rep',
			cellEditor: 'agRichSelectCellEditor',
			editable: true,
			cellRenderer: (p) => (p.value ? titleCase(p.value) : ''),
			cellEditorParams: {
				values: srData?.map((el) => el?.salesRepName),
				formatValue: (value) => titleCase(value),
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 160,
			},
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{ field: 'salesRepCrmId', hide: true },
		{
			field: 'sourcingSpecialistCrmName',
			headerName: 'SS',
			cellEditor: 'agRichSelectCellEditor',
			cellRenderer: (p) => (p.value ? titleCase(p.value) : ''),
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellEditorParams: {
				values: ssDetails?.map((el) => el?.salesRepName),
				formatValue: (value) => titleCase(value),
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 160,
			},
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{ field: 'sourcingSpecialistCrmId', hide: true },
		{
			field: 'isBuy',
			headerName: 'STK/LT',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellEditor: 'agRichSelectCellEditor',
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
			cellEditorParams: {
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 100,
				values: ['Stock', 'Lead Time'],
			},
		},
		{
			field: 'inqDateTime',
			headerName: 'Inquiry Date Time',
			cellClass: styles['gray-cell'],
			headerClass: styles['blue-header'],
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			minWidth: 170,
			sort: 'desc',
			cellRenderer: (p) => (
				<span className={styles['font-weight']}>
					{p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : ''}
				</span>
			),
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{ field: 'customerProspect', hide: true },
		{
			field: 'cp_subject_line',
			headerName: 'Subject',
			headerClass: styles['blue-header'],
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: memo(CustomerProspect),
			minWidth: 150,
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{
			field: 'accountName',
			headerName: 'Customer/Prospect',
			headerClass: styles['blue-header'],
			cellEditor: 'agRichSelectCellEditor',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellEditorParams: {
				values:
					Array.isArray(filterCRMAccount) &&
					filterCRMAccount?.map((el) => el?.ACCOUNT_NAME),
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 360,
			},
			minWidth: 190,
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{
			field: 'cp_rfq_email',
			headerName: 'Email',
			floatingFilter: false,
			cellRenderer: (p) => {
				const trimmedString = p?.data?.cp_rfq_email?.replace(/=+$/, '');
				return (
					<>
						{p?.data?.cp_rfq_email ? (
							<ToolTip title="Preview mail">
								<MdMail
									onClick={(e) => {
										if (e.ctrlKey && e.button === 0) {
											window.open(
												`${messageURl?.url}${trimmedString}%3D.eml`,
												'_blank'
											);
										} else {
											openDrawer(p.data?.cp_rfq_email);
											setCellData(p);
										}
									}}
									style={{ fontSize: '16px', cursor: 'pointer' }}
								/>
							</ToolTip>
						) : (
							''
						)}
					</>
				);
			},
			cellStyle: { padding: '4px 0 0 25px' },
			maxWidth: 80,
			cellClass: styles['gray-cell'],
			headerClass: styles['blue-header'],
		},
		{
			field: 'webLink',
			headerName: 'CF File',
			cellRenderer: memo(CFfileActions),
			headerClass: styles['blue-header'],
			floatingFilter: false,
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{
			field: 'isUrgent',
			headerName: 'Urgent?',
			headerClass: styles['blue-header'],
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: (p) => (
				<span
					style={{
						color: p.value === 'Urgent' ? 'red' : 'black',
						fontWeight: p.value === 'Urgent' ? 'bold' : 'normal',
					}}
				>
					{p.value}
				</span>
			),
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 100,
				values: ['Normal', 'Urgent'],
			},
		},
		{
			field: 'noOfLines',
			headerName: '# of Lines',
			headerClass: styles['blue-header'],
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: (p) => (
				<>{p?.value ? p?.value?.toLocaleString('en-US') : ''}</>
			),
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{
			field: 'ssStatus',
			headerName: 'SS Status',
			headerClass: styles['pink-header'],
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ['Acknowledged', 'MM Sent', 'Completed', 'Dropped'],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 100,
			},
		},
		{
			field: 'ssActionDateTime',
			headerName: 'SS Action Date Time',
			cellClass: styles['gray-cell'],
			headerClass: styles['pink-header'],
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			suppressHeaderMenuButton: true,
			minWidth: 170,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: (p) => (
				<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : ''}</>
			),
		},
		{
			field: 'ssRemarks',
			headerName: 'SS Remarks',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: (p) => (
				<>
					{p?.value ? (
						<ToolTip title={p?.value} placement="left">
							{p?.value}
						</ToolTip>
					) : (
						''
					)}
				</>
			),
			headerClass: styles['pink-header'],
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			field: 'poInHand',
			headerName: 'PO In Hand?',
			headerClass: styles['pink-header'],
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ['Yes', 'No'],
				valueListMaxHeight: 220,
				valueListMaxWidth: 100,
			},
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
		},
		{
			field: 'stageName',
			headerName: 'Stage',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			headerClass: styles['dark-blue-header'],
			cellEditor: 'agRichSelectCellEditor',
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
			cellEditorParams: {
				values: [
					'Design',
					'NPI',
					'MP Bid',
					'MP Buy',
					'MP Savings',
					'MP Shortage',
					'MP Spot',
				],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 120,
			},
		},
		{
			field: 'srStatus',
			headerName: 'SR Status',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			headerClass: styles['dark-blue-header'],
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				values: ['Quoted', 'Quotation dropped'],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 120,
			},
		},
		{
			field: 'srRemarks',
			headerName: 'SR Remarks',
			minWidth: 140,
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: (p) => (
				<>
					{p?.value ? (
						<ToolTip title={p?.value} placement="left">
							{p?.value}
						</ToolTip>
					) : (
						''
					)}
				</>
			),
			headerClass: styles['dark-blue-header'],
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
		{
			field: 'mngrReviewed',
			headerName: 'Mgr. Reviewed?',
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			// headerClass: styles['dark-blue-header'],
			cellEditor: 'agRichSelectCellEditor',
			minWidth: 140,
			cellClassRules: {
				'cell-error-bg': (p) => !p.value,
			},
			cellEditorParams: {
				values: ['Yes', 'No'],
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 120,
			},
		},
		{
			field: 'mngrRemarks',
			headerName: 'Mgr. Remarks',
			minWidth: 140,
			editable: (p) => (p?.data?.salesRepCrmId ? true : false),
			cellRenderer: (p) => (
				<>
					{p?.value ? (
						<ToolTip title={p?.value} placement="left">
							{p?.value}
						</ToolTip>
					) : (
						''
					)}
				</>
			),
			cellEditor: 'agLargeTextCellEditor',
			cellEditorPopup: true,
			cellEditorParams: {
				maxLength: 256,
				rows: 5,
				cols: 50,
			},
		},
	];

	const [custoSumColumnDefs] = useState([
		{ field: 'salesRep', headerName: 'Sales Rep' },
		{ field: 'customer', headerName: 'Customer' },
		{ field: 'noOfRFQs', headerName: '# of RFQs', sort: 'desc' },
		{
			field: 'noOfLines',
			headerName: '# of Lines',
			cellRenderer: (p) => p.value?.toLocaleString('en-US'),
		},
		{ field: 'lastRFQ', headerName: 'Last RFQ received' },
	]);

	const [salesRepSumColumnDefs] = useState([
		{ field: 'salesRep', headerName: 'Sales Rep' },
		{ field: 'totalRFQcurrentFY', headerName: 'FY 25 # of RFQs', sort: 'desc' },
		{
			field: 'totalnoOfLinesCurrentFY',
			headerName: 'FY 25 # of Lines',
		},
		{ field: 'april', headerName: 'April' },
		{ field: 'may', headerName: 'May' },
		{ field: 'june', headerName: 'June' },
		{ field: 'july', headerName: 'July' },
		{ field: 'august', headerName: 'August' },
		{ field: 'september', headerName: 'September' },
		{ field: 'october', headerName: 'October' },
		{ field: 'november', headerName: 'November' },
		{ field: 'december', headerName: 'December' },
		{ field: 'january', headerName: 'January' },
		{ field: 'february', headerName: 'February' },
		{ field: 'march', headerName: 'March' },
	]);

	const [salesRepSumRFQColumnDefs] = useState([
		{ field: 'salesRep', headerName: 'Sales Rep' },
		{ field: 'totalRFQcurrentFY', headerName: 'FY 25 # of RFQs', sort: 'desc' },
		{
			field: 'totalnoOfLinesCurrentFY',
			headerName: 'FY 25 # of Lines',
		},
		{ field: 'aprilRFQs', headerName: 'April' },
		{ field: 'mayRFQs', headerName: 'May' },
		{ field: 'juneRFQs', headerName: 'June' },
		{ field: 'julyRFQs', headerName: 'July' },
		{ field: 'augustRFQs', headerName: 'August' },
		{ field: 'septemberRFQs', headerName: 'September' },
		{ field: 'octoberRFQs', headerName: 'October' },
		{ field: 'novemberRFQs', headerName: 'November' },
		{ field: 'decemberRFQs', headerName: 'December' },
		{ field: 'januaryRFQs', headerName: 'January' },
		{ field: 'februaryRFQs', headerName: 'February' },
		{ field: 'marchRFQs', headerName: 'March' },
	]);

	const unAttendedRfq =
		isNotEmptyArray(mpnQueueList) &&
		mpnQueueList?.filter(
			(el) => el?.ssStatus === '' || el?.ssStatus === 'Acknowledged'
		);

	const Notification = () => {
		return (
			<p>
				There are{' '}
				<span style={{ color: '#c00000' }}>{unAttendedRfq?.length}</span>{' '}
				unattended customer RFQs in the queue. Please attend.
			</p>
		);
	};

	const exportToExcel = () => {
		const mpnQueueExcelParams = {
			sheetName:
				queueView === 'View Open Queue' || queueView === 'View full Queue'
					? 'MPN Queue'
					: queueView === 'Customer Summary'
					? 'MPN Queue Customer Summary'
					: queueView === 'Sales Rep Summary'
					? 'MPN Queue Sales Rep Summary'
					: 'export',
			fileName:
				queueView === 'View Open Queue' || queueView === 'View full Queue'
					? 'MPN Queue.xlsx'
					: queueView === 'Customer Summary'
					? 'MPN Queue Customer Summary.xlsx'
					: queueView === 'Sales Rep Summary'
					? 'MPN Queue Sales Rep Summary.xlsx'
					: 'export.xlsx',
			onlySelected:
				gridApi.getSelectedRows()?.length > 0 && gridApi.getSelectedRows(),
			columnKeys:
				queueView === 'View Open Queue' || queueView === 'View full Queue'
					? [
							'id',
							'salesRepCrmName',
							'sourcingSpecialistCrmName',
							'isBuy',
							'inqDateTime',
							'accountName',
							'cp_subject_line',
							'cp_rfq_email',
							'webLink',
							'isUrgent',
							'noOfLines',
							'ssStatus',
							'ssActionDateTime',
							'ssRemarks',
							'srStatus',
							'srRemarks',
							'mngrRemarks',
					  ]
					: queueView === 'Customer Summary'
					? ['salesRep', 'customer', 'noOfRFQs', 'noOfLines', 'lastRFQ']
					: queueView === 'Sales Rep Summary'
					? ['salesRep', 'date', 'noOfRFQs', 'noOfLines']
					: [],
			processCellCallback: (p) => {
				if (
					p.column.getColDef().field === 'inqDateTime' ||
					p.column.getColDef().field === 'lastRFQ'
				) {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.colId === 'cp_rfq_email') {
					if (p?.value) {
						const trimmedString = p?.value?.replace(/=+$/, '');
						return `${messageURl?.url}${trimmedString}%3D.eml`;
					}
					return '';
				}
				if (p.column.getColDef().field === 'ssActionDateTime') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				if (p.column.getColDef().field === 'cp_received_date') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY hh:mm A');
					}
					return '';
				}
				return p.value;
			},
		};

		gridApi.exportDataAsExcel(mpnQueueExcelParams);
		gridApi.deselectAll();
	};

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	const handleQueueView = (e) => {
		setQueueView(e.target.value);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressKeyboardEvent: suppressNavigation,
			suppressHeaderKeyboardEvent: suppressUpDownNavigation,
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const defaultColDefV2 = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const onCellValueChanged = useCallback(
		async (event) => {
			const cellValue = event.data;

			if (cellValue.salesRepCrmName?.length > 0) {
				cellValue.salesRepCrmId =
					srData?.filter(
						(el) => cellValue.salesRepCrmName === el?.salesRepName
					)?.[0]?.salesRepCrmId || cellValue.salesRepCrmId;
			}

			const formFields = {
				email: userMail,
				salesRepCrmId:
					srData?.filter(
						(el) => cellValue.salesRepCrmName === el?.salesRepName
					)?.[0]?.salesRepCrmId || cellValue.salesRepCrmId,
				salesRepCrmName: cellValue?.salesRepCrmName,
				sourcingSpecialistCrmId:
					ssDetails?.filter(
						(el) => cellValue.sourcingSpecialistCrmName === el?.salesRepName
					)?.[0]?.salesRepCrmId || cellValue.sourcingSpecialistCrmId,
				sourcingSpecialistCrmName: cellValue.sourcingSpecialistCrmName || '',
				inqDateTime: cellValue.inqDateTime || null,
				customerProspect:
					filterCRMAccount?.filter(
						(el) => cellValue.accountName === el?.ACCOUNT_NAME
					)?.[0]?.ID || cellValue.customerProspect,
				accountName: cellValue.accountName || '',
				webLink: cellValue.webLink || '',
				cfFile: cellValue.cfFile || '',
				isUrgent: cellValue.isUrgent || 'Normal',
				noOfLines: parseInt(cellValue?.noOfLines) || 0,
				ssStatus: cellValue.ssStatus || '',
				ssStatusName: cellValue.ssStatusName || '',
				ssActionDateTime: cellValue?.ssStatus
					? moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
					: null,
				srStatus: cellValue.srStatus || '',
				srRemarks: cellValue.srRemarks || '',
				cp_subject_line: cellValue.cp_subject_line || '',
				cp_customer: cellValue.cp_customer || '',
				cp_customer_mail: cellValue.cp_customer_mail || '',
				cp_salesRep_mail: cellValue.cp_salesRep_mail || '',
				cp_received_date: cellValue.cp_received_date || null,
				cp_rfq_email: cellValue.cp_rfq_email || '',
				cp_rfq_id: cellValue.cp_rfq_id || 0,
				isBuy: cellValue.isBuy || null,
				ssRemarks: cellValue.ssRemarks || '',
				poInHand: cellValue.poInHand || null,
				updateCounter:
					parseInt(cellValue?.noOfLines) > 0 && cellValue?.webLink
						? (parseInt(cellValue?.updateCounter) || 0) + 1
						: 0,
				parentId: cellValue.parentId || 0,
				bomLineNo: cellValue.bomLineNo || 0,
				mngrReviewed: cellValue.mngrReviewed || null,
				mngrRemarks: cellValue.mngrRemarks || '',
				stageName: cellValue.stageName || '',
				stage:
					cellValue.stageName === 'Design'
						? 1
						: cellValue.stageName === 'NPI'
						? 2
						: cellValue.stageName === 'MP Bid'
						? 3
						: cellValue.stageName === 'MP Buy'
						? 4
						: cellValue.stageName === 'MP Savings'
						? 5
						: cellValue.stageName === 'MP Shortage'
						? 6
						: cellValue.stageName === 'MP Spot'
						? 7
						: null,
				smartId: '',
				id: cellValue.id || 0,
			};

			const rowNode = event.api.getRowNode(event.data.id);

			event.colDef.field === 'salesRepCrmName' &&
				!event.oldValue &&
				delete formFields.id;

			if (event.colDef.field === 'salesRepCrmName') {
				rowNode.setDataValue(
					'salesRepCrmId',
					srData?.filter(
						(el) => cellValue.salesRepCrmName === el?.salesRepName
					)?.[0]?.salesRepCrmId
				);
			}

			if (event.colDef.field === 'sourcingSpecialistCrmName') {
				rowNode.setDataValue(
					'sourcingSpecialistCrmId',
					ssDetails?.filter(
						(el) => el?.salesRepName === cellValue.sourcingSpecialistCrmName
					)?.[0]?.salesRepCrmId
				);
			}

			if (event.colDef.field === 'accountName') {
				rowNode.setDataValue(
					'customerProspect',
					filterCRMAccount?.filter(
						(el) => el?.ACCOUNT_NAME === cellValue.accountName
					)?.[0]?.ID
				);
			}

			if (event.colDef.field === 'ssStatus') {
				rowNode.setDataValue(
					'ssActionDateTime',
					cellValue?.ssStatus
						? moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
						: null
				);
			}

			axios({
				method: 'post',
				url: cellValue?.id
					? `https://internal.supremecomponents.com/api/externalgateway.php?route=updatempnque`
					: `https://internal.supremecomponents.com/api/externalgateway.php?route=savempnque`,
				data: JSON.stringify(formFields),
			})
				.then((response) => {
					if (event.colDef.field === 'salesRepCrmName' && !event.oldValue) {
						let responseid = response.data?.id;
						axios
							.get(
								`https://internal.supremecomponents.com/api/externalgateway.php?route=getmpnquebyid&id=${
									responseid || cellValue?.id
								}&email=${userMail}`
							)
							.then((response) => {
								setMpnQueueList((prev) =>
									filterUniqueIdsAndRecent([...prev, response?.data?.data?.[0]])
								);
								const column = event.column.colDef.field;
								setTimeout(() => {
									event.api.refreshCells({
										force: true,
										columns: [column],
										rowNodes: [event.node],
									});
									event?.api?.tabToNextCell();
								}, 500);
							});
					}
				})
				.catch((err) => {
					message.error('Something went wrong please try again later!');
				});
		}, // eslint-disable-next-line
		[]
	);

	const getRowId = useMemo(() => {
		return (params) => {
			return params.data.id;
		};
	}, []);

	const addRfqtoQueue = () => {
		const newRow = { currentTime: Date.now(), isUrgent: 'Normal' };
		setMpnQueueRowData([newRow, ...mpnQueueRowData]);
	};

	const processCellForClipboard = (p) => {
		if (p.column.getColDef().field === 'inqDateTime') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'cp_rfq_email') {
			if (p?.value) {
				const trimmedString = p?.value?.replace(/=+$/, '');
				return `${messageURl?.url}${trimmedString}%3D.eml`;
			}
			return '';
		}
		if (p.column.colId === 'ssActionDateTime') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}
		if (p.column.colId === 'cp_received_date') {
			if (p?.value) {
				return moment(p.value).format('MMM-DD-YYYY hh:mm A');
			}
			return '';
		}

		return p.value;
	};

	const copyToClipboard = () => {
		if (gridApi) {
			gridApi.getSelectedRows()?.length > 0
				? gridApi.getSelectedRows()
				: gridApi?.selectAll();
			gridApi.copySelectedRowsToClipboard({
				includeHeaders: true,
				columnKeys: [
					'id',
					'salesRepCrmName',
					'sourcingSpecialistCrmName',
					'isBuy',
					'inqDateTime',
					'accountName',
					'cp_subject_line',
					'cp_rfq_email',
					'webLink',
					'isUrgent',
					'noOfLines',
					'ssStatus',
					'ssActionDateTime',
					'ssRemarks',
					'srStatus',
					'srRemarks',
					'mngrRemarks',
				],
			});
			gridApi.deselectAll();
		}
		message.open({
			type: 'success',
			content: 'Copied to Clipboard',
		});
	};

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
	];

	useEffect(() => {
		if (queueView === 'View Open Queue') {
			isNotEmptyArray(mpnQueueList) &&
				setMpnQueueRowData(
					mpnQueueList?.filter(
						(el) => el?.ssStatus === '' || el?.ssStatus === 'Acknowledged'
					)
				);
		} else if (queueView === 'View full Queue') {
			isNotEmptyArray(mpnQueueList) && setMpnQueueRowData(mpnQueueList);
		} else if (queueView === 'Customer Summary') {
			isNotEmptyArray(mpnQueueList) &&
				setMpnQueueRowData(
					createCustomerSummaryTable(mpnQueueList, customerSalesRepArray)
				);
		} else if (queueView === 'Sales Rep Summary') {
			isNotEmptyArray(mpnQueueList) &&
				setMpnQueueRowData(createSalesRepSummaryTable(mpnQueueList));
			isNotEmptyArray(mpnQueueList) &&
				setSalesRepSumNoOfRfq(createSalesRepSummaryTable(mpnQueueList));
		} //eslint-disable-next-line
	}, [mpnQueueList, setMpnQueueRowData, queueView]);

	const dispatchClickEvent = (element) => {
		const clickEvent = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		element.dispatchEvent(clickEvent);
	};

	const onCellKeyDown = (event) => {
		const handledColIds = [
			'salesRepCrmName',
			'sourcingSpecialistCrmId',
			'accountName',
			'cp_subject_line',
			'webLink',
		];

		if (
			event.event.key === 'Enter' &&
			handledColIds.includes(event.column.colId)
		) {
			const rowIndex = event.rowIndex;
			const cellSelector = `.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${event.column.colId}"]`;
			const cell = document.querySelector(cellSelector);

			if (cell) {
				// First, try to find a span element
				const spanElement = cell.querySelector('span');
				if (spanElement) {
					spanElement.click();
				}

				// If no span found, then try to find an SVG element
				const svgElement = cell.querySelector('svg');
				if (svgElement) {
					dispatchClickEvent(svgElement);
				}
			} else {
			}
		}
	};

	// Remove filters shortcut
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	const fieldandHeader = [
		{
			field: 'sourcingSpecialistCrmName',
			header: 'SS',
			mandate: true,
		},
		{
			field: 'isBuy',
			header: 'STK/LT',
			mandate: true,
		},
		{
			field: 'inqDateTime',
			header: 'Inquiry Date Time',
			mandate: true,
		},
		{
			field: 'cp_subject_line',
			header: 'Subject',
			mandate: true,
		},
		{
			field: 'accountName',
			header: 'Customer/Prospect',
			mandate: true,
		},
		{
			field: 'cp_rfq_email',
			header: 'Email',
			mandate: false,
		},
		{
			field: 'webLink',
			header: 'CF File',
			mandate: true,
		},
		{
			field: 'isUrgent',
			header: 'Urgent?',
			mandate: true,
		},
		{
			field: 'noOfLines',
			header: '# of Lines',
			mandate: true,
		},
		{
			field: 'ssStatus',
			header: 'SS Status',
			mandate: false,
		},
		{
			field: 'ssActionDateTime',
			header: 'SS Action Date Time',
			mandate: false,
		},
		{
			field: 'ssRemarks',
			header: 'SS Remarks',
			mandate: false,
		},
		{
			field: 'poInHand',
			header: 'PO In Hand?',
			mandate: true,
		},
		{
			field: 'stageName',
			header: 'Stage',
			mandate: true,
		},
		{
			field: 'srStatus',
			header: 'SR Status',
			mandate: false,
		},
		{
			field: 'srRemarks',
			header: 'SR Remarks',
			mandate: false,
		},
		{
			field: 'mngrReviewed',
			header: 'Mgr. Reviewed?',
			mandate: true,
		},
		{
			field: 'mngrRemarks',
			header: 'Mgr. Remarks',
			mandate: false,
		},
	];

	const onBtStartEditing = useCallback((rowIndex, colKey) => {
		gridRef.current.api.setFocusedCell(rowIndex, colKey);
		// gridRef.current.api.startEditingCell({
		// 	rowIndex,
		// 	colKey,
		// });
	}, []);

	// const onCellEditingStarted = useCallback(
	// 	(event) => {
	// 		const rowData = event.data;
	// 		const rowIndex = event.rowIndex;
	// 		const fieldName = event.column.userProvidedColDef.field;

	// 		// Only proceed if there is an ID in the row data
	// 		if (!rowData.id) {
	// 			return;
	// 		}

	// 		// Find the index of the current field in the fieldandHeader list
	// 		const currentFieldIndex = fieldandHeader.findIndex(
	// 			(item) => item.field === fieldName
	// 		);

	// 		// Array to accumulate missing mandatory fields
	// 		const missingFields = [];

	// 		// Check mandatory fields up to and including the current field
	// 		for (let i = 0; i <= currentFieldIndex; i++) {
	// 			const fieldObj = fieldandHeader[i];

	// 			// Only check mandatory fields
	// 			if (fieldObj.mandate) {
	// 				const cellValue = rowData[fieldObj.field]; // Get the value of each mandatory field

	// 				// Check if the mandatory field value is empty
	// 				if (
	// 					!cellValue ||
	// 					(typeof cellValue === 'string' && cellValue.trim() === '')
	// 				) {
	// 					missingFields.push(fieldObj.header); // Add the header name to missing fields array
	// 				}
	// 			}
	// 		}

	// 		// If there are missing mandatory fields, show a single notification
	// 		if (missingFields.length > 0) {
	// 			const uniqueFields = [...new Set(missingFields)]; // Ensure only unique field names are shown
	// 			// const fieldsMessage = uniqueFields.join(', '); // Join fields with 'and' for better readability

	// 			notification.destroy();
	// 			notification.warning({
	// 				message: (
	// 					<p style={{ fontSize: '16px', fontWeight: 600 }}>
	// 						You have left the pink mandatory fields empty. The system will not
	// 						save your record if you do not complete the following mandatory
	// 						fields:
	// 					</p>
	// 				),
	// 				description: (
	// 					<div style={{ fontSize: '14px', fontWeight: 600 }}>
	// 						{missingFields.map((el) => (
	// 							<p style={{ color: 'red' }}>{el}</p>
	// 						))}
	// 					</div>
	// 				),
	// 				duration: 10,
	// 				style: {
	// 					borderRadius: '2px',
	// 				},
	// 				placement: 'top',
	// 				pauseOnHover: true,
	// 				showProgress: true,
	// 			});

	// 			// Focus on the first missing mandatory field
	// 			const firstMissingField = fieldandHeader.find(
	// 				(fieldObj) => fieldObj.header === uniqueFields[0]
	// 			);

	// 			if (firstMissingField) {
	// 				onBtStartEditing(rowIndex, firstMissingField.field);
	// 			}
	// 		}
	// 	}, //eslint-disable-next-line
	// 	[onBtStartEditing]
	// );

	const onCellEditingStarted = useCallback(
		(event) => {
			const rowData = event.data;
			const rowIndex = event.rowIndex;

			// Only proceed if there is an ID in the row data
			if (!rowData.id) {
				return;
			}

			// Array to accumulate missing mandatory fields
			const missingFields = [];

			// Check all mandatory fields
			fieldandHeader.forEach((fieldObj) => {
				if (fieldObj.mandate) {
					const cellValue = rowData[fieldObj.field]; // Get the value of each mandatory field

					// Check if the mandatory field value is empty
					if (
						!cellValue ||
						(typeof cellValue === 'string' && cellValue.trim() === '')
					) {
						missingFields.push(fieldObj.header); // Add the header name to missing fields array
					}
				}
			});

			// If there are missing mandatory fields, show a single notification
			if (missingFields.length > 0) {
				const uniqueFields = [...new Set(missingFields)]; // Ensure only unique field names are shown

				notification.destroy();
				notification.warning({
					message: (
						<p style={{ fontSize: '16px', fontWeight: 600 }}>
							You have left the pink mandatory fields empty. The system will not
							save your record if you do not complete the following mandatory
							fields:
						</p>
					),
					description: (
						<div style={{ fontSize: '14px', fontWeight: 600 }}>
							{uniqueFields.map((el) => (
								<p key={el} style={{ color: 'red' }}>
									{el}
								</p>
							))}
						</div>
					),
					duration: 10,
					style: {
						borderRadius: '2px',
					},
					placement: 'top',
					pauseOnHover: true,
					showProgress: true,
				});

				// Focus on the first missing mandatory field
				const firstMissingField = fieldandHeader.find(
					(fieldObj) => fieldObj.header === uniqueFields[0]
				);

				if (firstMissingField) {
					onBtStartEditing(rowIndex, firstMissingField.field);
				}
			}
		}, //eslint-disable-next-line
		[onBtStartEditing]
	);

	return (
		<>
			{mpnQueueLoading && (
				<div className={styles['loading-wrapper']}>
					<LoadingMessage message="Loading..." />
				</div>
			)}

			{mpnQueueError && !mpnQueueLoading && (
				<div className={styles['error-wrapper']}>
					<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
				</div>
			)}

			{!mpnQueueLoading && !mpnQueueError && mpnQueueHttpStatusCode === 401 && (
				<AccessDenied message="Contact Admin for Access" />
			)}

			{!mpnQueueLoading && !mpnQueueError && mpnQueueHttpStatusCode === 200 && (
				<div className={styles['mpn-queue-wrapper']}>
					<BreadCrumb
						route1="Sourcing"
						route2="MPN Queue"
						icon={<MoneyCollectOutlined style={{ paddingRight: '5px' }} />}
					/>
					<SectionInfo
						title="MPN Queue"
						info={
							userType === 'admin' || userType === 'ceo'
								? '	This section displays all parts in the queue. You have full edit rights to all items in the queue.'
								: userType === 'sm'
								? 'This section displays all of yours and your team’s parts in the queue. Add or manage the parts you want your Sourcing Specialist to source for you or your team.'
								: userType === 'ss'
								? 'This section displays all MPNs that have been queued by your sales representative. Please action all open items.'
								: 'This section displays the queue utilized by the Sourcing Specialist to begin sourcing parts. Add or manage the parts you want your specialist to source.'
						}
					/>

					<InnerHeader
						exportToExcel={exportToExcel}
						updatePage
						onUpdate={sourcingMpnQueue}
						updateText="Refresh MPN Queue"
						copyToClipboard={copyToClipboard}
						lastReportText="Last Refreshed on"
						lastReport={moment().format('MMM-DD-YYYY hh:mm A')}
					/>

					<div className={styles['mpn-queue']}>
						<NotificationBar message={<Notification />} wraningBar />
						<div className={styles['dropdown-addrfq-wrapper']}>
							<div className={styles['dropdown-wrapper']}>
								<Radio.Group
									defaultValue={queueView}
									onChange={handleQueueView}
									disabled={mpnQueueLoading || mpnQueueError}
								>
									<RadioTwo value="View Open Queue">View Open Queue</RadioTwo>
									<RadioTwo value="View full Queue">View Full Queue</RadioTwo>
									{userType === 'admin' && (
										<>
											<RadioTwo value="Customer Summary">
												Customer Summary
											</RadioTwo>
											<RadioTwo value="Sales Rep Summary">
												Sales Rep Summary
											</RadioTwo>
										</>
									)}
								</Radio.Group>
							</div>

							{(queueView === 'View Open Queue' ||
								queueView === 'View full Queue') && (
								<div className={styles['add-rfq']}>
									<Button
										onClick={addRfqtoQueue}
										disabled={mpnQueueLoading || mpnQueueError}
									>
										<IoMdAddCircle style={{ fontSize: '16px' }} />
										Add RFQ
									</Button>
								</div>
							)}
						</div>

						<div className={`ag-theme-custom ${styles['table-wrapper']}`}>
							{(queueView === 'View Open Queue' ||
								queueView === 'View full Queue') && (
								<AgGridReact
									ref={gridRef}
									onGridReady={onGridReady}
									onCellKeyDown={onCellKeyDown}
									rowData={mpnQueueRowData}
									columnDefs={columnDefs}
									rowSelection={'multiple'}
									rowMultiSelectWithClick={true}
									suppressRowClickSelection={true}
									onCellValueChanged={onCellValueChanged}
									defaultColDef={defaultColDef}
									animateRows={true}
									pagination={true}
									undoRedoCellEditing={true}
									enableCellChangeFlash={true}
									enableRangeSelection={true}
									suppressRowHoverHighlight={true}
									rowHeight={30}
									paginationPageSize={50}
									excelStyles={excelStyles}
									processCellForClipboard={processCellForClipboard}
									overlayNoRowsTemplate={`<p style='font-size:16px; font-weight:600'>Click on Add RFQ Button to add an RFQ<p/>`}
									paginationPageSizeSelector={false}
									getRowId={getRowId}
									onCellEditingStopped={onCellEditingStarted}
								/>
							)}
							{(queueView === 'Customer Summary' ||
								queueView === 'Sales Rep Summary') && (
								<>
									{queueView === 'Sales Rep Summary' && (
										<h2 style={{ paddingBottom: '16px' }}>
											Grouped by No. of Lines
										</h2>
									)}

									<AgGridReact
										onGridReady={onGridReady}
										rowData={mpnQueueRowData}
										columnDefs={
											queueView === 'Sales Rep Summary'
												? salesRepSumColumnDefs
												: custoSumColumnDefs
										}
										defaultColDef={defaultColDefV2}
										animateRows={true}
										pagination={true}
										excelStyles={excelStyles}
										paginationPageSizeSelector={false}
										enableRangeSelection={true}
										enableCharts={true}
										enableCellChangeFlash={true}
									/>
								</>
							)}
							{queueView === 'Sales Rep Summary' && (
								<>
									<h2 style={{ padding: '16px 0' }}>Grouped by No. of RFQs</h2>
									<AgGridReact
										onGridReady={onGridReady}
										rowData={salesRepSumNoOfRfq}
										columnDefs={salesRepSumRFQColumnDefs}
										defaultColDef={defaultColDefV2}
										animateRows={true}
										pagination={true}
										excelStyles={excelStyles}
										paginationPageSizeSelector={false}
										enableRangeSelection={true}
										enableCharts={true}
										enableCellChangeFlash={true}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
			{drawerMail && (
				<Drawer
					title={
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<p className={styles['mail-subject']}>
								<ToolTip placement="bottomLeft" title={drawerMailData?.subject}>
									{drawerMailData?.subject}
								</ToolTip>
							</p>
							<Link
								style={{ textDecoration: 'none' }}
								target="_blank"
								to={`${messageURl?.url}${cellData?.data?.cp_rfq_email?.replace(
									/=+$/,
									''
								)}%3D.eml`}
							>
								<Button type="bordered">Download Mail</Button>
							</Link>
						</div>
					}
					placement="right"
					onClose={closeDrawer}
					open={drawerMail}
					size={'large'}
				>
					{drawerMailLoading && <LoadingOutlined />}
					{drawerMailData && (
						<>
							<div className={styles['mail-details-wrapper']}>
								<div className={styles['mail-user-info']}>
									<Avatar
										className={styles['avatar']}
										style={{
											backgroundColor:
												avatarBgColor[drawerMailData?.fromName?.charAt(0)] ||
												avatarBgColor.default,
										}}
									>
										{drawerMailData?.fromName?.charAt(0)?.toUpperCase()}
									</Avatar>
									<div className={styles['users-info']}>
										<p className={styles['sender-name']}>
											{drawerMailData?.fromName}
										</p>
										{drawerMailData?.toRecipients && (
											<p className={styles['to']}>
												<span className={styles['title']}>To:</span>{' '}
												{drawerMailData?.toRecipients}
											</p>
										)}
										{drawerMailData?.ccRecipients && (
											<p className={styles['cc']}>
												<span className={styles['title']}>Cc:</span>{' '}
												{drawerMailData?.ccRecipients}
											</p>
										)}
										{drawerMailData?.bccRecipients && (
											<p className={styles['bcc']}>
												<span className={styles['title']}>Bcc:</span>{' '}
												{drawerMailData?.bccRecipients}
											</p>
										)}
									</div>
								</div>

								<p className={styles['date-info']}>
									{moment(drawerMailData?.receivedDateTime).format(
										'MMM-DD-YYYY hh:mm A'
									)}
								</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: drawerMailData?.html }} />
						</>
					)}
				</Drawer>
			)}
		</>
	);
};

export default MPNQueue;
