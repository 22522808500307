import { Col, Form, message, Row, Upload, Radio } from 'antd';
import { useContext, useMemo, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import { IoMdAttach } from 'react-icons/io';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

import Input from '../../../../components/input';
import Button from '../../../../components/button';
import UploadedFile from '../../../../components/uploadedFile';
import { useFetch } from '../../../../hooks/useFetch';
import Select from '../../../../components/select';
import Drawer from '../../../../components/drawer';
import FranchisedBrandsContext from '../../../../context/franchisedBrands';
import { API_BASE_URL } from '../../../../config/baseURLs';
import { COUNTRY_CODES } from '../../../../columns';

import styles from './form.module.scss';

const AddFranchisedBrandForm = ({
	drawer,
	setDrawer,
	initialValues,
	isLoading,
	data,
	dataError,
	setData,
}) => {
	const { getData, formValues, setFormValues, extractFileName } = useContext(
		FranchisedBrandsContext
	);

	const sortDescription = useRef(null);
	const {
		data: headQuaters,
		loading,
		error,
	} = useFetch(
		'https://countriesnow.space/api/v0.1/countries/info?returns=currency%2Cflag%2CunicodeFlag%2Cdialcode'
	);

	const [disabled, setDisabled] = useState(false);

	const closeDrawer = () => {
		setDrawer(false);
		form.resetFields();
		setDisabled(false);
		setData({});
		setFormValues({
			...formValues,
			companyLogo: null,
			authorizationLetter: null,
			corpPresentation: null,
			sortDescription: '',
			longDescription: '',
			headquaters: null,
		});
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		setDisabled(true);
		const formData = new FormData();

		formData.append('companyName', values.companyName);
		formData.append('websiteUrl', values.websiteUrl);
		if (formValues.companyLogo) {
			formData.append('companyLogo', formValues.companyLogo);
		}
		formData.append(
			'authorizationLetter',
			formValues.authorizationLetter || null
		);
		formData.append(
			'corporatePresentation',
			formValues.corpPresentation || null
		);
		formData.append('displayonUI', values.displayonUI);
		formData.append('displayInRegions', values.displayInRegions);
		formData.append(
			'headquarters',
			JSON.stringify(formValues.headquaters) || null
		);
		formData.append('shortDescription', values.shortDescription);
		formData.append('longDescription', values.longDescription);

		axios({
			method: data?.id ? 'put' : 'post',
			url: data?.id
				? `${API_BASE_URL}/franchised/${data.id}`
				: `${API_BASE_URL}/franchised`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				getData();
				message.success(response.data?.message);
			})
			.catch((err) => {
				message.error('Something Went Wrong!');
			})
			.finally(() => {
				setDrawer(false);
				form.resetFields();
				setDisabled(false);
				setFormValues({
					companyLogo: null,
					authorizationLetter: null,
					corpPresentation: null,
					shortDescription: '',
					longDescription: '',
					headquarters: null,
				});
			});
	};

	const companyLogoProps = {
		name: 'company_logo',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;
			const isImage = file.name.split('.').pop().toLowerCase();
			const validExtensions = ['png', 'jpg', 'jpeg', 'webp', 'svg'];

			if (!validExtensions.includes(isImage)) {
				message.error('Please select a image');
				return false;
			}

			if (fileSize > 5242880) {
				// file size in bytes (1MB = 1,048,576 bytes)
				message.error('The file cannot be more than 5MB');
				return false;
			}

			setFormValues({ ...formValues, companyLogo: file });
			return false;
		},
		customRequest: () => null,
	};

	const authorizationLetterProps = {
		name: 'company_file',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;
			const isPDF = file.type;

			if (isPDF !== 'application/pdf') {
				message.error('Please select a PDF');
				return false;
			}

			// file size in bytes (1MB = 1,048,576 bytes)
			if (fileSize > 5242880) {
				message.error('The file cannot be more than 5MB');
				return false;
			}

			setFormValues({ ...formValues, authorizationLetter: file });
			return false;
		},
		customRequest: () => null,
	};

	const corpPresentationProps = {
		name: 'company_file',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const isPPT = file.type;
			const fileSize = file.size;

			if (
				isPPT !==
				'application/vnd.openxmlformats-officedocument.presentationml.presentation'
			) {
				message.error('Please select a PPT');
				return false;
			}

			if (fileSize > 50 * 1024 * 1024) {
				message.error('The file cannot be more than 50MB');
				return false;
			}

			setFormValues({ ...formValues, corpPresentation: file });
			return false;
		},
		customRequest: () => null,
	};

	const buttons = [
		'bold',
		'underline',
		'italic',
		'|',
		'align',
		'|',
		'ul',
		'ol',
		'|',
		'eraser',
	];

	const config = useMemo(
		() => ({
			readonly: false,
			spellcheck: true,
			language: 'en',
			toolbarButtonSize: 'medium',
			toolbarAdaptive: false,
			showCharsCounter: false,
			showWordsCounter: false,
			showXPathInStatusbar: false,
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			placeholder: 'Add Remarks',
			//defaultActionOnPaste: "insert_clear_html",
			buttons: buttons,
			uploader: {
				insertImageAsBase64URI: true,
			},
			width: '100%',
			disabled: disabled,
			clipboard: {
				match: 'wordHtml', // Options: 'word', 'wordHtml', 'html', 'plain' - match clipboard content type
				processPastedHTML: (html) => {
					// Example: Remove all styles and classes from pasted content
					const doc = new DOMParser().parseFromString(html, 'text/html');
					doc.body.querySelectorAll('*').forEach((el) => {
						el.removeAttribute('style');
						el.removeAttribute('class');
					});
					return doc.body.innerHTML;
				},
				// Additional clipboard settings can be added here
			},
		}), //eslint-disable-next-line
		[]
	);

	// Fields

	// Company Name (Mandatory) - added
	// Website URL - added
	// Company Logo - added
	// Short Description - added
	// Long Description - added
	// Authorization Letter (Link to the uploaded file) - Added
	// Corporate Presentation (Link to the uploaded file) - Added
	// Tagging System (Multiple selection field)
	// Parts List
	// Headquarters - added
	// Authorized Countries
	// Enable/Disable (Radio box: Default value Enable) - Added
	// Exclude option (Only for All regions and Japanese) (Values All Regions, Show only in Japan, Remove from Japan) - Added

	return (
		<div className={styles['wrapper']}>
			{drawer && (
				<Drawer
					open={drawer}
					footer={
						<div className={styles['actions']}>
							<Button loading={disabled} onClick={() => form.submit()}>
								Submit
							</Button>
							<Button type="bordered" onClick={closeDrawer}>
								Cancel
							</Button>
						</div>
					}
					title={
						data?.companyName ? (
							<span>
								Your are editing <b>{data?.companyName}</b>
							</span>
						) : (
							'Add Franchised Line'
						)
					}
					onClose={closeDrawer}
					destroyOnClose
					width={700}
				>
					{isLoading ? (
						<LoadingOutlined />
					) : (
						<div className={styles['form-wrapper']}>
							<Form
								name="add-franchised-brand-form"
								form={form}
								onFinish={onFinish}
								validateTrigger={['onBlur', 'onChange']}
								labelCol={{ span: 24 }}
								requiredMark={false}
								disabled={disabled}
								className={styles['form']}
								initialValues={initialValues}
							>
								<Row gutter={16}>
									<Col sm={24} md={12}>
										<Form.Item
											label="Company Name"
											name="companyName"
											rules={[
												{
													required: true,
													message: 'Please enter Company Name!',
												},
											]}
										>
											<Input placeholder="Company Name" />
										</Form.Item>
									</Col>
									<Col sm={24} md={12}>
										<Form.Item
											label="Website URL"
											name="websiteUrl"
											rules={[
												{
													required: true,
													message: 'Please enter Website URL!',
													type: 'url',
												},
											]}
										>
											<Input placeholder="Website URL" />
										</Form.Item>
									</Col>
									{/* Company Logo file */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Company Logo"
											name="companyLogo"
											id="company_logo"
											rules={[
												{
													required: true,
													message: 'Please add Company Logo!',
												},
											]}
										>
											{formValues?.companyLogo ? (
												<UploadedFile
													url={
														formValues?.companyLogo?.name
															? formValues?.companyLogo?.name
															: formValues.companyLogo
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.companyLogo?.name
															? formValues?.companyLogo?.name
															: extractFileName(formValues.companyLogo)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															companyLogo: null,
														});
														form.setFieldsValue({ companyLogo: null });
														form.validateFields(['companyLogo']);
													}}
													canDownload
												/>
											) : (
												<Upload {...companyLogoProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Company Logo
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col>
									{/* Authorization Letter file */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Authorization Letter"
											name="authorizationLetter"
											id="authorizationLetter"
										>
											{formValues?.authorizationLetter ? (
												<UploadedFile
													url={
														formValues?.authorizationLetter?.name
															? formValues?.authorizationLetter?.name
															: formValues?.authorizationLetter
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.authorizationLetter?.name
															? formValues?.authorizationLetter?.name
															: extractFileName(formValues?.authorizationLetter)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															authorizationLetter: null,
														});
														form.setFieldsValue({
															authorizationLetter: null,
														});
													}}
													canDownload
												/>
											) : (
												<Upload {...authorizationLetterProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Authorization Letter
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col>
									{/* Corporate Presentation file */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Corporate Presentation"
											name="corporatePresentation"
											id="corporatePresentation"
										>
											{formValues?.corpPresentation ? (
												<UploadedFile
													url={
														formValues?.corpPresentation?.name
															? formValues?.corpPresentation?.name
															: formValues?.corpPresentation
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.corpPresentation?.name
															? formValues?.corpPresentation?.name
															: extractFileName(formValues?.corpPresentation)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															corpPresentation: null,
														});
														form.setFieldsValue({
															corporatePresentation: null,
														});
													}}
													canDownload
												/>
											) : (
												<Upload {...corpPresentationProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Corporate Presentation
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col>
									{/* Display in UI */}
									<Col sm={24} md={12}>
										<Form.Item label="Display on UI?" name="displayonUI">
											<Radio.Group>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									{/* Display in Regions */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Display in Regions?"
											name="displayInRegions"
										>
											<Radio.Group>
												<Radio value={'Show in All Regions'}>
													Show in All Regions
												</Radio>
												<Radio value={'Show Only in Japan'}>
													Show Only in Japan
												</Radio>
												<Radio value={'Hide in Japan'}>Hide in Japan</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									{/* Headquarters */}
									<Col sm={24} md={12}>
										<Form.Item label="Headquaters" name="headquaters">
											{error ? (
												<Input placeholder="Enter Headquaters" />
											) : (
												<Select
													loading={loading}
													allowClear
													showSearch
													placeholder="Select Headquaters"
													type="bordered"
													defaultValue={
														data?.headquarters &&
														JSON.parse(data?.headquarters)?.name
													}
													onChange={(value, options) => {
														setFormValues({
															...formValues,
															headquaters: options?.data,
														});
													}}
													options={headQuaters
														?.sort((a, b) => a.name.localeCompare(b.name))
														?.map((el) => ({
															value: el.name,
															data: {
																...el,
																flagImage: `https://flags.fmcdn.net/data/flags/mini/${
																	COUNTRY_CODES[el?.name]
																}.png`,
															},
															label: (
																<div className={styles['flags']}>
																	<img
																		className={styles['flag']}
																		src={`https://flags.fmcdn.net/data/flags/mini/${
																			COUNTRY_CODES[el?.name]
																		}.png`}
																		alt={el.name}
																	/>
																	<span>{el?.name}</span>
																</div>
															),
														}))}
												/>
											)}
										</Form.Item>
									</Col>
									{/* Sort description */}
									<Col sm={24}>
										<Form.Item label="Sort Description" name="shortDescription">
											<JoditEditor
												ref={sortDescription}
												config={config}
												onBlur={(content) =>
													setFormValues({
														...formValues,
														sortDescription: content,
													})
												}
											/>
										</Form.Item>
									</Col>
									{/* Long description */}
									<Col sm={24}>
										<Form.Item label="Long Description" name="longDescription">
											<JoditEditor
												ref={sortDescription}
												config={config}
												onBlur={(content) =>
													setFormValues({
														...formValues,
														longDescription: content,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					)}
				</Drawer>
			)}
		</div>
	);
};

export default AddFranchisedBrandForm;
